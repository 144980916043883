import React from 'react';
import Popover from '@material-ui/core/Popover';

interface PopOverProps {
  component: React.ReactElement;
  hover?: boolean;
}

const PopOver: React.FC<PopOverProps> = ({ component, hover, children, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const newComponent = React.cloneElement(component, {
    ...(!hover && { onClick: handlePopoverOpen }),
    ...(hover && { onMouseEnter: handlePopoverOpen }),
    ...(hover && { onMouseLeave: handlePopoverClose }),
  });

  return (
    <>
      <div>{newComponent}</div>
      <Popover
        {...rest}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <div onClick={handlePopoverClose}>{children}</div>
      </Popover>
    </>
  );
};

export default PopOver;
