import styled from 'styled-components';
import { SPACE } from './enums';
import { mobileStyle } from './utils';

const { DEF } = SPACE;

const Space = {
  All: styled.div`
    padding: ${props => props.padding || DEF};
    margin: ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding: ${DEF}; margin: ${DEF}`) : '')}
  `,
  Right: styled.div`
    padding-right: ${props => props.padding || DEF};
    margin-right: ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding-right: ${DEF}; margin-right: ${DEF}`) : '')}
  `,
  Left: styled.div`
    padding-left: ${props => props.padding || DEF};
    margin-left: ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding-left: ${DEF}; margin-left: ${DEF}`) : '')}
  `,
  Top: styled.div`
    padding-top: ${props => props.padding || DEF};
    margin-top: ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding-top: ${DEF}; margin-top: ${DEF}`) : '')}
  `,
  Bottom: styled.div`
    padding-bottom: ${props => props.padding || DEF};
    margin-bottom: ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding-bottom: ${DEF}; margin-bottom: ${DEF}`) : '')}
  `,
  Hor: styled.div`
    padding: ${DEF} ${props => props.padding || DEF};
    margin: ${DEF} ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding: ${DEF}; margin: ${DEF}`) : '')}
  `,
  Ver: styled.div`
    padding: ${props => props.padding || DEF} ${DEF};
    margin: ${props => props.margin || DEF} ${DEF};

    ${props => (props.desktop ? mobileStyle(`padding: ${DEF}; margin: ${DEF}`) : '')}
  `,
  Free: styled.div`
    padding: ${props => props.padding || DEF};
    margin: ${props => props.margin || DEF};

    ${props => (props.desktop ? mobileStyle(`padding: ${DEF}; margin: ${DEF}`) : '')}
  `,
};

export default Space;
