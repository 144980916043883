import styled from 'styled-components';
import { WEIGHT, FONT, SPACE } from './enums';
import Div from './div';

export const H1 = styled(Div)`
  font-weight: ${WEIGHT.SEMI_BOLD};
  font-size: ${FONT.XLARGE};
  padding: ${SPACE.LARGE};
  text-align: center;
`;

export const H2 = styled(Div)`
  font-weight: ${WEIGHT.SEMI_BOLD};
  font-size: ${FONT.LARGE};
  padding: ${SPACE.LARGE};
  text-align: center;
`;

export const H3 = styled(Div)`
  font-weight: ${WEIGHT.SEMI_BOLD};
  font-size: ${FONT.MEDIUM};
  padding: ${SPACE.LARGE};
  text-align: center;
`;
