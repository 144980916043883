import intl from '$gintl';

const toast = params => {
  const toast = document.createElement('ion-toast');
  toast.message = intl(params.message, params.key) || intl('ERROR.UNKNOWN');
  toast.duration = params.duration || 2500;
  toast.position = params.position || 'top';
  toast.color = params.color;
  toast.cssClass = params.cssClass || '';

  const rootEl = document.getElementById('root-app');

  if (rootEl) {
    rootEl.appendChild(toast);
    return toast.present();
  }
};

export default toast;
