import _ from 'lodash';
import React from 'react';
import { IonSelect, IonSelectOption, IonItem, IonLabel } from '@ionic/react';

import { Text } from '$gintl';

interface FilterSelectorProps {
  onChange: (e) => void;
  filters: Array<any>;
}

const FilterSelector: React.FC<FilterSelectorProps> = React.memo(
  ({ onChange, filters }) => {
    return (
      <IonItem>
        <IonLabel position="stacked">
          <Text k="TABLE.FILTER.SAVED_FILTERS_LABEL" />
        </IonLabel>
        <IonSelect
          onIonChange={e => {
            onChange(e.detail.value);
          }}>
          {filters.map((col, i) => (
            <IonSelectOption value={col.filterId} key={i}>
              {col.filterId}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  },
  (pp, np) => _.isEqual(pp.filters, np.filters),
);

export default FilterSelector;
