import _ from 'lodash';
import React from 'react';
import { IonSelect, IonSelectOption, IonItem, IonLabel } from '@ionic/react';

import CellModel from '$gbusiness/models/cell';
import { Text } from '$gintl';

interface ColumnSelectorProps {
  onChange: (e) => void;
  values: object | null;
  colsToFilter: Array<CellModel>;
}

const ColumnSelector: React.FC<ColumnSelectorProps> = React.memo(
  ({ onChange, values, colsToFilter }) => {
    return (
      <IonItem>
        <IonLabel position="stacked">
          <Text k="TABLE.FILTER.COLUMN_FIELD" />
        </IonLabel>
        <IonSelect
          value={values}
          multiple={true}
          onIonChange={e => {
            onChange(e.detail.value);
          }}>
          {colsToFilter.map((col, i) => (
            <IonSelectOption value={col.value} key={i}>
              <Text k={col.label} />
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  },
  (pp, np) => _.isEqual(pp.values, np.values),
);

export default ColumnSelector;
