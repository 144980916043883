export default interface CustomerModel {
  customerCode: string;
  name?: string;
  owner?: string;
  email?: string;
  phone?: string;
  address?: string;
  address2?: string;
  zip?: string;
}

export const deriveRawToCustomer = raw => {
  return {
    customerCode: raw.customerCode,
    ...(raw.customerName && { name: raw.customerName }),
    ...(raw.customerOwner && { owner: raw.customerOwner }),
    ...(raw.customerEmail && { email: raw.customerEmail }),
    ...(raw.customerPhone && { phone: raw.customerPhone }),
    ...(raw.customerAddress && { address: raw.customerAddress }),
    ...(raw.customerAddress2 && { address2: raw.customerAddress2 }),
    ...(raw.customerZip && { zip: raw.customerZip }),
  };
};
