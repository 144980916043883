import styled from 'styled-components';

export const Wrapper = styled.div`
  .wrapper {
  }
  .opened {
    max-height: 0;
  }
  .closed {
    max-height: 0px;
  }

  .item1-enter {
    opacity: 0;
    max-height: 0;
  }
  .item1-enter-active {
    opacity: 1;
    max-height: ${props => props.maxHeight};
    transition: all ${props => props.duration}ms linear;
  }
  .item1-exit {
    max-height: ${props => props.maxHeight};
    opacity: 1;
  }
  .item1-exit-active {
    opacity: 0;
    max-height: 0px;
    transition: all ${props => props.duration}ms linear;
  }
  .item1-exit-done {
    max-height: 0px;
    display: none;
  }
  .item1-enter-done {
    max-height: ${props => props.maxHeight};
    display: block;
  }
`;

export const WrapperItem = styled.div`
  ion-item::part(native) {
    min-height: initial;
  }
  ion-icon {
    font-size: 24px;
    margin-right: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  ion-icon.transparent {
    color: transparent;
  }
`;
