import React from 'react';
import { DialogTitle, DialogContent, DialogActions, DialogProps, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DialogModal } from './styles';

interface ModalProps extends DialogProps {
  title?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  minWidth?: string;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

const MuiModal: React.FC<ModalProps> = ({
  title = '',
  hasCloseButton = true,
  children,
  minWidth,
  onClose,
  footer,
  ...rest
}) => {
  return (
    <DialogModal {...rest} width={minWidth}>
      {hasCloseButton && (
        <IconButton onClick={onClose} className="close-button">
          <CloseIcon />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </DialogModal>
  );
};

export default MuiModal;
