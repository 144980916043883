import { INPUT_TYPES } from '$gbusiness/enums';
import { validateEmail, requiredValidation, validatePassword } from '$ghelpers/input';
import InputRowModel from '$gbusiness/models/inputRow';

const FORGOT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        gridSize: 12,
        validation: [requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        gridSize: 12,
        validation: [requiredValidation],
      },
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 12,
        validation: [
          requiredValidation,
          {
            condition: v => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.PASSWORD,
        gridSize: 12,
        validation: [
          requiredValidation,
          {
            condition: v => !validatePassword(v),
            message: 'INVALID_PASSWORD',
          },
        ],
      },
    ],
  },
];

export default FORGOT_FORM;
