import React from 'react';

import { configs } from '$configs';
import { Link } from '$gcomponents/primitives';
import { SORT, ROUTES, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: e => `${e.firstName} ${e.lastName} ${e.email}`,
  selectedValue: e => e.firstName,
  onSelect: (e, history) => {
    history.push(`${ROUTES.USERS}/${e.userId}`);
  },
};

export const TABLE_CONFIG = {
  endpoint: configs.api.users.list,
  isPublic: false,
  color: COLORS.PRIMARY,
  cellPadding: '4px',
  isStripped: true,
  maxWidth: 600,
  filter: {
    color: COLORS.PRIMARY,
  },
};

// export const TABLE_CONFIG = {
//   endpoint: configs.api.invoice.list,
//   isPublic: false,
//   mockData: mockTransactions,
//   deriveToModel: deriveRawToTransactions,
//   color: COLORS.SECONDARY,
//   pageSize: 200,
//   cellPadding: `3px`,
//   isStripped: true,
//   minWidth: 960,
//   shouldVirtualize: false,
//   virtualized: {
//     rowHeight: 36,
//     headerHeight: 60,
//     checkWidth: 60,
//     threshold: 10,
//     additionalHeight: 40,
//   },
//   filter: {
//     color: COLORS.PRIMARY,
//   },
// };

const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.USERS.COLS.ID',
    value: 'userId',
    align: 'center',
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 100,
  },
  {
    label: 'SCREEN.USERS.COLS.FIRST_NAME',
    value: 'firstName',
    component: row => <Link route={`${ROUTES.USERS}/${row.userId}`}>{row.firstName}</Link>,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.USERS.COLS.LAST_NAME',
    value: 'lastName',
    component: row => <Link route={`${ROUTES.USERS}/${row.userId}`}>{row.lastName}</Link>,
    sortable: SORT.ASC,
    alwaysShow: true,
    width: 200,
  },
  {
    label: 'SCREEN.USERS.COLS.EMAIL',
    value: 'email',
    sortable: SORT.ASC,
    width: 400,
  },
];

export default TABLE;
