import React, { useState } from 'react';

import { connect } from 'react-redux';
import { IonContent, IonPage, IonPopover } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';
import { ApiTable } from '$gcomponents/reusables';
import ListFilterWrapper from '$components/listFilterWrapper';

import ListTabs from './components/tabs';
import TABLE, { TABLE_CONFIG } from './table';

import LeftComponent from './components/leftComponents';
import PopMenu from './components/popMenu';
import PaymentRequestModal from '$components/PaymentRequestModal';

const TransactionsScreen: React.FC = () => {
  const [dataLength, setDataLength] = useState(undefined);
  const [showPaymentRequestModal, setShowPaymentRequestModal] = useState(false);
  const [filter, setFilter] = useState({ query: '', dateStart: null, dateEnd: new Date() });
  const [showActions, setShowActions] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined,
  });

  const onTableLoad = len => {
    setDataLength(len);
  };

  const {
    endpoint,
    isPublic,
    cellPadding,
    pageSize,
    minWidth,
    isStripped,
    virtualized,
    shouldVirtualize,
    deriveToModel,
    color,
    mockData,
  } = TABLE_CONFIG;
  const { headerHeight, rowHeight, checkWidth } = virtualized;
  const TAB_HEIGHT = 40;
  const showTableScreen = (dataLength || 0) > 0;

  const leftContainer = <LeftComponent filter={filter} onFilterChange={newValue => setFilter(newValue)} />;
  const tabComponent = <ListTabs height={TAB_HEIGHT} />;

  return (
    <IonPage>
      <Header title="SCREEN.TRANSACTIONS.TITLE" />
      <IonPopover
        isOpen={showActions.open}
        event={showActions.event}
        onDidDismiss={e => {
          setShowActions({ open: false, event: undefined });
        }}>
        <PopMenu
          onClick={action => {
            setShowActions({ open: false, event: undefined });
            if (action === 'ADD') alert('add');
          }}
        />
      </IonPopover>
      <IonContent>
        <ListFilterWrapper
          shouldVirtualize={shouldVirtualize}
          isHidden={!showTableScreen}
          tabComponent={tabComponent}
          leftComponent={leftContainer}>
          <>
            <ApiTable
              dataSource={{
                endpoint,
                isPublic,
                mockData,
                pageSize,
                shouldVirtualize,
                deriveToModel,
              }}
              styles={{
                color,
                cellPadding,
                minWidth,
                headerHeight,
                rowHeight,
                checkWidth,
                isStripped,
              }}
              itemActions={{
                clickAction: row => {
                  console.log('click action', row);
                },
              }}
              TABLE={TABLE}
              onTableLoad={onTableLoad}
            />
          </>
        </ListFilterWrapper>
      </IonContent>
      <PaymentRequestModal show={showPaymentRequestModal} onClose={() => setShowPaymentRequestModal(false)} />
    </IonPage>
  );
};

const mapStateToProps = () => ({ resetOnRoute: false });

const connected = connect(mapStateToProps, null);

export default connected(screen(TransactionsScreen));
