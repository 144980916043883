import CardModel from './cardModel';

export default interface TransactionModel extends CardModel {
  transactionId: number;
  amount: number;
  paymentResult: string;
  refund: string;
  atuh: string;
  refNum: string;
  userName: string;
  datetime: string;
}

const derivePaymentResult = raw => {
  return raw.failedAt ? 'Failed' : 'Checked';
};

const deriveRefundStatus = raw => {
  if (raw.voidAt) return 'Void';
  if (raw.refundAt) return 'Refund';
  if (raw.paidAt) return 'Purchase';
  return 'Pending';
};

const deriveTime = raw => {
  if (raw.voidAt) return raw.voidAt;
  if (raw.refundAt) return raw.refundAt;
  if (raw.paidAt) return raw.paidAt;
  if (raw.failedAt) return raw.failedAt;
};

export const deriveRawToTransactions = raw => {
  return {
    transactionId: raw.transactionId,
    cardHolder: raw.cardHolder,
    cardType: raw.cardType,
    last4: raw.last4,
    expiry: raw.expDate,

    amount: raw.amount,
    paymentResult: derivePaymentResult(raw),
    refundStatus: deriveRefundStatus(raw),
    datetime: deriveTime(raw),
    atuh: raw.atuh,
    refNum: raw.refNum,
    userName: raw.userName,
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 535,
//   list: [
//     '{{repeat(10, 500)}}',
//     {
//       invoiceId: "{{index() + 1}}",
//       transactionId: function (t) { if (t.integer(0, 20) > 1) return this.invoiceId; else return 0; },
//       amount: "{{floating(10, 1100, 2)}}",
//       customerCode: function (t) { return '' + t.integer(100000, 999999).toString(); },
//       userName: "{{firstName() + ' ' + surname()}}",
//       cardType: function (t) { if (this.transactionId > 0) return t.random('Visa', 'Mastercard', 'Amex', 'Discover'); else return ''; },
//       cardHolder: function (t) { if (this.transactionId > 0) return t.firstName() + ' ' + t.surname(); else return ''; },
//       last4: function (t) { if (this.transactionId > 0) return t.integer(1000, 9999); else return ''; },
//       expDate: function (t) {  if (this.transactionId > 0) return t.integer(1, 12) + '/' + t.integer(22, 25); else return ''; },
//       atuh: function (t) {  if (this.transactionId > 0) return '' + t.integer(100000, 999999).toString(); else return ''; },
//       refNum: function (t) { return this.transactionId; },
//       createdAt: function (t) { if (this.transactionId > 0) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       paidAt: function (t) { if (this.transactionId > 0) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       failedAt: function (t) { if(this.transactionId == 0 || this.paidAt) return ''; else return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); },
//       voidAt: function (t) { if(this.transactionId > 0 && this.paidAt && t.integer(0,100) > 95) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; },
//       refundAt: function (t) { if(this.transactionId > 0 && this.paidAt && !this.voidAt && t.integer(0,10) > 9) return t.date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss'); else return ''; }
//     }
//   ]
// }
