import { Dispatch } from 'redux';

import { configs } from '$configs';
import { localStorage } from '$gbusiness/services';
import { fetchApi } from '$gbusiness/services/api';
import loginMock from '$gbusiness/mocks/auth/login.json';
import registerMock from '$gbusiness/mocks/auth/register.json';
import resetMock from '$gbusiness/mocks/auth/resetPassword.json';
import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';
import { LoginModel, RegisterModel } from '$gbusiness/models/user';

import {
  AuthActionTypes,
  PROCESSING,
  LOGGING_IN,
  LOGGING_OUT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_FAILURE,
} from './types';
import { addMinutesToTimestamp } from '$ghelpers/util';
import { SET_SCREEN_STATE } from '../router/types';

const storeAuth = async response => {
  const authObj = {
    accessToken: response.accessToken,
    refreshToken: response.refreshToken,
    expTimestp: addMinutesToTimestamp(response.expiredAt),
    userId: response.user?.userId,
  };

  await localStorage.setStorageItems(authObj);
};

export function login(param: LoginModel) {
  return async (dispatch: Dispatch<AuthActionTypes>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await fetchApi({
      url: configs.api.login,
      param,
      isPublic: true,
      mockData: loginMock,
    });

    if (!response || response.errors) {
      dispatch({
        type: LOGIN_FAILURE,
        err: 'MESSAGE.LOGIN_FAIL',
      });

      toast({ message: response.errors || 'MESSAGE.LOGIN_FAIL', color: COLORS.DANGER });
      return;
    }

    await storeAuth(response);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response,
    });
  };
}

export function logout() {
  return (dispatch: Dispatch<AuthActionTypes>) => {
    dispatch({
      type: LOGGING_OUT,
      loadingText: 'PROGRESS.LOGGING_OUT',
    });

    setTimeout(() => {
      localStorage.clearAuth();
      window.location.reload();
    }, 1000);
  };
}

export function register(param: RegisterModel) {
  return async (dispatch: Dispatch<AuthActionTypes>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.register,
      param,
      isPublic: true,
      mockData: registerMock,
    });

    if (!response || response.err) {
      dispatch({
        type: REGISTER_FAILURE,
        err: response?.err || 'MESSAGE.REGISTER_FAIL',
      });

      toast({ message: response.err || 'MESSAGE.REGISTER_FAIL', color: COLORS.DANGER });
      return;
    }

    await storeAuth(response);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: response,
    });
  };
}

export function requestPasswordReset(email) {
  return async (dispatch: Dispatch, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.forgotPassword,
      param: { email },
      isPublic: true,
    });

    if (!response || response.err) {
      dispatch({
        type: LOGIN_FAILURE,
        err: 'ERROR.NETWORK',
      });

      toast({ message: response.err || 'MESSAGE.NETWORK', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: SET_SCREEN_STATE,
      payload: 'FORGET_PASSWORD',
    });
  };
}

export function resetPassword(token, email, password) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.resetPassword,
      param: {
        token,
        email,
        password,
      },
      mockData: resetMock,
    });

    if (!response || response.err) {
      dispatch({
        type: LOGIN_FAILURE,
        err: 'ERROR.NETWORK',
      });

      toast({ message: response.err || 'MESSAGE.NETWORK', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: SET_SCREEN_STATE,
      payload: 'PASSWORD_RESET',
    });
  };
}
