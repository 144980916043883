import { Button, ButtonProps as MuiButtonProps } from '@material-ui/core';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { COLORS } from '$gbusiness/enums';
import { SPACE } from '$gstyles';

interface ButtonProps {
  children: React.ReactNode;
  color?: MuiButtonProps['color'];
  fullWidth?: boolean;
  onClick: () => void;
  size?: MuiButtonProps['size'];
  variant?: MuiButtonProps['variant'];
  disabled?: MuiButtonProps['disabled'];
  isLoading?: boolean;
  loadingText?: string;
  [x: string]: any;
}

const Component: React.FC<ButtonProps> = ({
  children,
  color = COLORS.PRIMARY,
  fullWidth = false,
  onClick,
  disabled,
  size = 'large',
  variant = 'contained',
  isLoading,
  loadingText = false,
  ...rest
}) => {
  const shouldDisable = isLoading || disabled ? true : false;
  return (
    <Button
      color={color}
      fullWidth={fullWidth}
      disabled={shouldDisable}
      size={size}
      variant={variant}
      {...rest}
      onClick={onClick}>
      {loadingText && isLoading ? loadingText : children}
      {isLoading && <CircularProgress size={16} style={{ marginLeft: SPACE.MEDIUM }} />}
    </Button>
  );
};

export default Component;
