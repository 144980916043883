import React from 'react';
import { IonLabel, IonIcon, IonChip } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';

import { COLORS } from '$gbusiness/enums';
import { Styles } from '$gstyles';

interface FilterItemProps {
  label: any;
  color?: string;
  onClose?: () => void;
}

const FilterItem: React.FC<FilterItemProps> = ({ label, color, onClose }) => {
  if (!label) return null;

  return (
    <Styles.Inline top="5px">
      <IonChip color={color || COLORS.SECONDARY} outline>
        <IonLabel>{label}</IonLabel>
        <IonIcon icon={closeCircle} onClick={onClose} />
      </IonChip>
    </Styles.Inline>
  );
};

export default FilterItem;
