import React from 'react';
import { Tab, TabWrapper } from '$styles/tabs';
import intl from '$intl';

interface ListTabProps {
  height: number;
}

const ListTabs: React.FC<ListTabProps> = ({ height }) => {
  return (
    <TabWrapper height={height}>
      <Tab className="active">{intl('SCREEN.TRANSACTIONS.TABS.ALL')}</Tab>
      <Tab>{intl('SCREEN.TRANSACTIONS.TABS.SENT')}</Tab>
      <Tab>{intl('SCREEN.TRANSACTIONS.TABS.VIEWED')}</Tab>
      <Tab>{intl('SCREEN.TRANSACTIONS.TABS.PAID')}</Tab>
    </TabWrapper>
  );
};

export default ListTabs;
