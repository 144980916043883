import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage, IonPopover } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import Header from '$components/header';
import { ApiTable } from '$gcomponents/reusables';
import ListFilterWrapper from '$components/listFilterWrapper';

import ListTabs from './components/tabs';
import EmptySection from './components/empty';
import LeftComponent from './components/leftComponents';
import TABLE, { TABLE_CONFIG } from './table';
import PopMenu from './components/popMenu';

const TransactionsScreen: React.FC = () => {
  const [dataLength, setDataLength] = useState(undefined);
  const [showActions, setShowActions] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined,
  });

  const onTableLoad = len => {
    setDataLength(len);
  };

  const {
    endpoint,
    isPublic,
    cellPadding,
    pageSize,
    minWidth,
    isStripped,
    virtualized,
    shouldVirtualize,
    deriveToModel,
    color,
    mockData,
  } = TABLE_CONFIG;
  const { headerHeight, rowHeight, checkWidth, additionalHeight } = virtualized;
  const TAB_HEIGHT = 40;

  const showEmptyScreen = dataLength === 0;
  const showTableScreen = (dataLength || 0) > 0;
  const leftContainer = <LeftComponent />;

  return (
    <IonPage>
      <Header />
      <IonPopover
        isOpen={showActions.open}
        event={showActions.event}
        onDidDismiss={e => {
          setShowActions({ open: false, event: undefined });
        }}>
        <PopMenu
          onClick={action => {
            setShowActions({ open: false, event: undefined });
            if (action === 'ADD') alert('add');
          }}
        />
      </IonPopover>
      <IonContent>
        {showEmptyScreen && <EmptySection />}
        <ListFilterWrapper
          shouldVirtualize={shouldVirtualize}
          isHidden={!showTableScreen}
          leftComponent={leftContainer}
          additionalHeight={TAB_HEIGHT}>
          <>
            <ListTabs height={TAB_HEIGHT} />
            <ApiTable
              dataSource={{
                endpoint,
                isPublic,
                mockData,
                pageSize,
                shouldVirtualize,
                deriveToModel,
              }}
              styles={{
                color,
                cellPadding,
                minWidth,
                headerHeight,
                rowHeight,
                checkWidth,
                isStripped,
                additionalHeight,
              }}
              itemActions={{
                clickAction: (e, row) => {
                  console.log('click action', row);
                  setShowActions({ open: true, event: e });
                },
                sendBill: row => {
                  console.log('send bill', row);
                },
              }}
              TABLE={TABLE}
              onTableLoad={onTableLoad}
              onSelection={rows => {
                console.log(rows);
              }}
            />
          </>
        </ListFilterWrapper>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = () => ({ resetOnRoute: false });

const connected = connect(mapStateToProps, null);

export default connected(screen(TransactionsScreen));
