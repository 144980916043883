import { configs } from '$configs';

const { display } = configs;

export const ENUMS = {
  MOBILE_WIDTH: display.mobileThreshold,
  DESKTOP_MIN_WIDTH: display.desktopMinThreshold,
  SPLIT_PANE_WIDTH: display.splitPaneThreshold,
  SPLIT_PANE_MIN_WIDTH: display.splitPaneMinThreshold,
};

export const BOX_SIZE = {
  XSMALL: '200px',
  SMALL: '300px',
  MEDIUM: '400px',
  LARGE: '600px',
  XLARGE: '800px',
  MOBILE: '40em',
  FULL: '100%',
};

export const SPACE = {
  DEF: '0',
  XXSMALL: '1px',
  XSMALL: '2px',
  SMALL: '4px',
  MEDIUM: '8px',
  LARGE: '16px',
  XLARGE: '32px',
  XXLARGE: '64px',
  CONTENT_PADDING: '16px',
};

export const FONT = {
  DEF: '1.6rem',
  XXSMALL: '1.0rem',
  XSMALL: '1.2rem',
  SMALL: '1.4rem',
  MEDIUM: '1.6rem',
  LARGE: '2.0rem',
  XLARGE: '2.8rem',
  XXLARGE: '3.6rem',
  XXXLARGE: '4.8rem',
};

export const FONT_FAMILY = {
  DEFAULT: 'var(--ion-font-family, inherit)',
  ARIAL: 'Arial, serif',
  VERDANA: 'Verdana, serif',
  TAHOMA: 'Tahoma, serif',
  IMPACT: 'Impact, serif',
  TIMES: 'Times, Times New Roman, serif',
  COURIER: 'Lucida Console, Courier, monospace',
  OPEN_SANS: "'Open Sans', sans-serif",
};

export const COLOR = {
  PRIMARY: 'var(--ion-color-primary)',
  SECONDARY: 'var(--ion-color-secondary)',
  TERTIARY: 'var(--ion-color-tertiary)',
  SUCCESS: 'var(--ion-color-success)',
  TEXT: 'var(--ion-color-dark)',
  DARK: 'var(--ion-color-dark)',
  LIGHT: 'var(--ion-color-light)',
  DANGER: 'var(--ion-color-danger)',
  WARNING: 'var(--ion-color-warning)',
  MEDIUM: 'var(--ion-color-medium)',
};

export const WEIGHT = {
  LIGHT: 300,
  NORMAL: 400,
  SEMI_BOLD: 500,
  BOLD: 600,
  BOLDEST: 700,
};
