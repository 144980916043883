import CustomerModel, { deriveRawToCustomer } from './customer';

export default interface InvoiceModel {
  invoiceId: number;
  invoiceType: string;
  invoiceNumber: string;
  seqNumber: number;
  email: string;
  customer: CustomerModel;
  amount: number;
  tax?: number;
  message?: string;
  notes?: string;
  userName: string;
  status: string;
  dueDate: string;
  datetime?: string;
}

const deriveStatus = raw => {
  if (raw.actionedAt) return 'Action Req.';
  if (raw.sentAt) return 'Sent';
  if (raw.viewedAt) return 'Viewed';
  if (raw.paidAt) return 'Paid';
  if (raw.createdAt) return '';
};

const deriveTime = raw => {
  if (raw.paidAt) return raw.paidAt;
  if (raw.viewedAt) return raw.viewedAt;
  if (raw.sentAt) return raw.sentAt;
  if (raw.actionedAt) return raw.actionedAt;
  if (raw.createdAt) return raw.createdAt;
};

export const deriveRawToInvoice = raw => {
  return {
    invoiceId: raw.invoiceId,
    invoiceNumber: raw.invoiceNumber,
    invoiceType: raw.invoiceType,
    seqNumber: raw.seqNumber,
    email: raw.email,
    customer: deriveRawToCustomer(raw),
    amount: raw.amount,
    ...(raw.tax && { tax: raw.tax }),
    ...(raw.message && { tax: raw.message }),
    ...(raw.notes && { tax: raw.notes }),
    userName: raw.userName,
    status: deriveStatus(raw),
    dueDate: raw.dueDate,
    ...(raw.sentAt && { sentAt: raw.sentAt }),
    datetime: deriveTime(raw),
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 535,
//   list: [
//     '{{repeat(5, 300)}}',
//     {
//       invoiceId: "{{index() + 1}}",
//       invoiceType: "{{random('Item Sales', 'Reg Bill', 'PPI')}}",
//       invoiceNumber: "{{integer(1000000, 9999999)}}",
//       seqNumber: "{{index() + 1}}",
//       customerCode: function (t, i) { return  this.customerId * 327; },
//       customerName: "{{firstName() + ' ' + surname()}}",
//       email: "{{email()}}",
//       amount: "{{floating(10, 1100, 2)}}",
//       notes: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       userName: "{{firstName() + ' ' + surname()}}",
//       dueDate: "{{date(new Date(), new Date(2021, 7, 10), 'YYYY-MM-dd')}}",
//       createdAt: "{{date(new Date(2021, 0, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       actionedAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       sentAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       viewedAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       paidAt: "{{date(new Date(2021, 3, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
