import _ from 'lodash';
import React from 'react';
import { IonItem, IonLabel, IonIcon, IonSelect } from '@ionic/react';
import { FastField, ErrorMessage } from 'formik';

import intl from '$gintl';
import { Styles } from '$gstyles';
import { input } from '$gcomponents/utils';
import { configs } from '$configs';

interface SelectInputProps {
  icon?: string;
  label?: string;
  name: string;
  formik?: any;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  position?: string;
  multiSelect?: boolean;
  options: React.ReactNode | null;
  defaultValue?: string | number | null;
  handleChange?: (e) => void;
}

const defaultProps: SelectInputProps = {
  icon: '',
  label: '',
  name: '',
  formik: {},
  disabled: false,
  readonly: false,
  placeholder: '',
  position: '',
  multiSelect: false,
  options: null,
  defaultValue: null,
  handleChange: () => {},
};

const getPosition = position => {
  return position || configs.defaultLabelPosition;
};

const SelectInput: React.FC<SelectInputProps> = ({
  icon,
  label,
  name,
  formik,
  disabled,
  readonly,
  placeholder,
  position,
  multiSelect,
  options,
  defaultValue,
  handleChange,
  ...props
}) => {
  const onChange = e => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const ionOptions = input.generateSelectOptions(options);

  return (
    <>
      <FastField name={name}>
        {({ form }) => (
          <>
            <IonItem className={readonly ? 'readonly' : ''}>
              {icon !== '' && <IonIcon slot="start" icon={icon} />}
              {label !== '' && <IonLabel position={getPosition(position)}>{intl(label)}</IonLabel>}
              <ErrorMessage name={name}>
                {e => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
              </ErrorMessage>
              <IonSelect
                {...props}
                name={name}
                disabled={disabled}
                placeholder={intl(placeholder) || undefined}
                onIonChange={onChange}
                value={_.get(form.values, name)}>
                {ionOptions}
              </IonSelect>
            </IonItem>
          </>
        )}
      </FastField>
    </>
  );
};

SelectInput.defaultProps = defaultProps;

export default SelectInput;
