import styled from 'styled-components';
import { FONT, WEIGHT } from './enums';

const Font = {
  DEF: styled.span`
    font-size: ${FONT.DEF};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  XXSMALL: styled.span`
    font-size: ${FONT.XXSMALL};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  XSMALL: styled.span`
    font-size: ${FONT.XSMALL};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  SMALL: styled.span`
    font-size: ${FONT.SMALL};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  MEDIUM: styled.span`
    font-size: ${FONT.MEDIUM};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  LARGE: styled.span`
    font-size: ${FONT.LARGE};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  XLARGE: styled.span`
    font-size: ${FONT.XLARGE};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  XXLARGE: styled.span`
    font-size: ${FONT.XXLARGE};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
  XXXLARGE: styled.span`
    font-size: ${FONT.XXXLARGE};
    font-weight: ${props => (props.weight ? WEIGHT.SEMI_BOLD : 'inherit')};
  `,
};

export default Font;
