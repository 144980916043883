import styled from 'styled-components';
import { IonToolbar } from '@ionic/react';

import { FONT } from '$gstyles';

export const Toolbar = styled(IonToolbar)`
  z-index: 9;
  width: 100%;
  height: 48px;
  background-color: #fff;

  ion-segment-button {
    white-space: initial;
    font-size: ${FONT.XSMALL};
    background: 1px solid #eee;
  }

  .MuiAppBar-root {
    position: absolute;
    .MuiTabs-indicator {
      background-color: var(--ion-color-secondary-contrast);
    }
  }

  &.ios .MuiAppBar-root {
    top: -3px;
  }
`;
