export const generateColValues = TABLE => {
  return TABLE.map(c => c.value);
};

export const generateColsToFilter = TABLE => {
  return TABLE.filter(c => !c.alwaysShow);
};

export const getActiveColumnTable = (TABLE, colsToDisplay) => {
  return TABLE.filter(c => c.alwaysShow || colsToDisplay.includes(c.value));
};

export const getColsToDisplay = TABLE => {
  return generateColValues(generateColsToFilter(TABLE));
};
