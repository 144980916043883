export const generateSelects = (pageSize, defaultValue = false) => {
  const selects: Array<boolean> = [];
  for (let i = 0; i < pageSize; i++) {
    selects.push(defaultValue);
  }

  return selects;
};

export const getCellLabel = (cells, value) => {
  const cell = cells.find(c => c.value === value);

  return cell?.label;
};

export const sortTable = (data, key, order) => {
  const newData = [...data];
  const orderValueLess = order === 'ASC' ? -1 : 1;
  const orderValueMore = order === 'ASC' ? 1 : -1;
  newData.sort((a, b) => {
    if (a[key] < b[key]) {
      return orderValueLess;
    }
    if (a[key] > b[key]) {
      return orderValueMore;
    }
    return 0;
  });

  return newData;
};
