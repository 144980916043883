import React from 'react';
import { useDispatch } from 'react-redux';
import { Text } from '$intl';
import { Div, SPACE } from '$gstyles';
import { Button } from '@material-ui/core';
import { invoiceActions } from '$business/redux/invoice';

import { EmptyWrapper, Circle } from './styles';
import { IonContent } from '@ionic/react';

interface EmptySectionProps {}

const EmptySection: React.FC<EmptySectionProps> = () => {
  const dispatch = useDispatch();
  const loadBill = () => {
    dispatch(invoiceActions.fetchInvoices());
  };

  return (
    <IonContent>
      <EmptyWrapper>
        <Circle>
          <Div>
            <Text>Bill hasn't been generated</Text>
          </Div>
          <Div>
            <Text>Click generate bill button</Text>
          </Div>
          <Div margin={SPACE.LARGE}>
            <Button onClick={loadBill} variant="contained" color="secondary">
              Load Bill
            </Button>
          </Div>
        </Circle>
      </EmptyWrapper>
    </IonContent>
  );
};

export default EmptySection;
