import styled from 'styled-components';
import { IonApp } from '@ionic/react';
import { ENUMS, SPACE } from '$gstyles';

export default styled(IonApp)`
  font-size: 1.5rem;
  line-height: normal;

  .pointer {
    cursor: pointer;
  }

  .block {
    display: block;
  }

  .flex {
    display: flex;
  }

  .inline {
    display: inline-block;
  }

  .center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pull-right {
    float: right;
  }

  .pull-left {
    float: left;
  }

  .link {
    color: var(--ion-color-primary);
    cursor: pointer;
  }

  .round {
    border-radius: 100%;
  }

  .transparent {
    color: transparent;
  }

  /* toaster */
  ion-toast {
    max-width: 400px;
    text-align: center;
    margin: auto;
    position: relative;
  }
  ion-toast.small {
    max-width: 250px;
  }
  ion-toast.large {
    max-width: 600px;
  }

  .ion-overlay-wrapper {
    --min-width: 300px;
  }

  .readonly {
    pointer-events: none;
  }

  ion-item.readonly ion-select::part(icon) {
    display: none;
  }

  ion-content ion-item.item {
    ion-select,
    ion-datetime,
    ion-input {
      font-size: 1.4rem;
    }
    ion-label.hydrated {
      font-size: 1.4rem;
    }
  }

  ion-title {
    font-size: 18px;
  }

  /* MATERIAL DEFAULT */
  .MuiSelect-select:focus {
    background-color: initial;
  }
  .MuiFormLabel-root {
    font-size: inherit;
  }
  .MuiInputAdornment-root ion-icon {
    font-size: 2rem;
  }

  .site-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: ${SPACE.MEDIUM}
  }

  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    .can-go-back ion-menu-button {
      display: none;
    }
  }

  /* @media screen and (min-width: ${ENUMS.SPLIT_PANE_MIN_WIDTH}) {
    .can-go-back > ion-header ion-back-button {
      display: none !important;
    }
  } */

  .ReactVirtualized__Table__headerColumn,
  .ReactVirtualized__Table__rowColumn {
    display: flex;
    align-items: center;
  }
`;
