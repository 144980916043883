export const KEYS = {
  UNITED_STATES: 'UNITED_STATES',
};

const LIST = Object.keys(KEYS);

export default LIST.map(item => ({
  label: `COUNTRY.${item}`,
  value: item,
}));

export type DATE_RANGE_TYPE = typeof LIST[number] | undefined;
