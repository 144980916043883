import styled from 'styled-components';
import { Space } from '$gstyles';

export const Wrapper = styled(Space.Top)`
  text-align: center;
  margin: auto;

  .logo {
    margin: auto;
    display: block;
    width: ${props => props.width || '200'}px;
  }
`;
