export const phone = value => {
  const mv = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !mv[2] ? mv[1] : mv[1] + '-' + mv[2] + (mv[3] ? '-' + mv[3] : '');
};

export const ssn = value => {
  const mv = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,4})/);
  return !mv[2] ? mv[1] : mv[1] + '-' + mv[2] + (mv[3] ? '-' + mv[3] : '');
};

export const cardNumber = value => {
  return value.length > 4 ? value.replace(/(\d{4}(?!\s))/g, '$1 ') : value;
};

export const expiry = value => {
  const mv = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,2})/);
  return !mv[2] ? mv[1] : mv[1] + '/' + mv[2];
};

export const currency = value => {
  return parseFloat(value).toFixed(2);
};
