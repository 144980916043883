export const fullScreen = `
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const vcenter = `
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
`;
