import styled from 'styled-components';
import { Div } from '$gstyles';

export const TabWrapper = styled(Div)`
  display: flex;
`;

export const Tab = styled(Div)`
  &&.active {
    background: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast-bw);
    border-color: var(--ion-color-secondary);
    cursor: initial;
  }

  &&:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-width: 1px;
  }
  &&:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  width: 25%;
  max-width: 130px;
  min-width: 50px;
  height: ${p => p.height || 36}px;
  background: var(--ion-color-light);
  color: var(--ion-color-light-medium-bw);
  border: 1px solid var(--ion-color-secondary);
  border-left-width: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
