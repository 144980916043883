import React from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { ROUTES as GROUTES } from '$gbusiness/enums';
import { ROUTES } from '$business/enums';

import PrivateRoute from './privateRoute';

interface RouterProps {
  isLoggedIn: boolean;
}

const AppOutlet: React.FC<RouterProps> = ({ isLoggedIn }) => {
  return (
    <IonRouterOutlet id="main">
      {ROUTES.LIST.map((menu, i) => (
        <PrivateRoute
          key={i}
          exact={menu.exact || true}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          isLoggedIn={isLoggedIn}
        />
      ))}
      {ROUTES.PUBLIC_LIST.filter(m => m.path !== GROUTES.LOGIN).map((menu, i) => (
        <Route
          key={i}
          path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}

      <Route
        path={GROUTES.DEFAULT_PUBLIC.path}
        component={isLoggedIn ? undefined : GROUTES.DEFAULT_PUBLIC.component}
        render={isLoggedIn ? () => <Redirect to={ROUTES.DEFAULT_APP_ROUTE} exact /> : undefined}
        exact
      />
    </IonRouterOutlet>
  );
};

export default AppOutlet;
