import { Dispatch } from 'redux';

import { configs } from '$configs';
import { fetchApi } from '$gbusiness/services/api';
import {
  CHECK_CUSTOMER_CODE_FAILURE,
  CHECK_CUSTOMER_CODE_SUCCESS,
  RESET_ALL,
  CREATE_INVOICE_FAILURE,
  CREATE_INVOICE_SUCCESS,
  FETCH_INVOICES_FAILURE,
  FETCH_INVOICES_SUCCESS,
  InvoiceActionTypes,
  IS_PROCESSING,
  PAYMENT_FAILURE,
  PAYMENT_SUCCESS,
  RESET_SUCCESS,
  SEND_INVOICES_FAILURE,
  SEND_INVOICES_SUCCESS,
  VALIDATE_INVOICE_SUCCESS,
  VALIDATE_INVOICE_FAILURE,
} from './types';
import { LOADING } from '$gbusiness/redux/loading/types';

import FetchInvoicesMock from '$business/mocks/fetchInvoices.json';
import CheckCustomerCodeMock from '$business/mocks/checkCustomerCode.json';
import CreatePaymentRequestMock from '$business/mocks/createPaymentRequest.json';
import ValidateInvoiceMock from '$business/mocks/validateInvoice.json';
import PayCardMock from '$business/mocks/payCard.json';

import { toast } from '$gcomponents/reusables';
import { COLORS } from '$gbusiness/enums';
import { deriveRawToInvoice } from '../../models/invoice';

export function fetchInvoices() {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
    });

    const response = await fetchApi({
      url: configs.api.invoice.list,
      method: 'GET',
      mockData: FetchInvoicesMock,
    });

    if (!response || !response?.success) {
      dispatch({
        type: FETCH_INVOICES_FAILURE,
        err: 'ERROR.FETCH_FAIL',
      });
      // FRONT-END function
      toast({ message: response.err || 'ERROR.FETCH_FAIL', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: FETCH_INVOICES_SUCCESS,
    });
  };
}

export function validateInvoice(uuid) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
    });

    const response = await fetchApi({
      url: configs.api.invoice.validate + uuid,
      method: 'GET',
      mockData: ValidateInvoiceMock,
    });

    if (!response || !response.isValid) {
      dispatch({
        type: VALIDATE_INVOICE_FAILURE,
        err: 'ERROR.ERROR',
      });
      return;
    }

    dispatch({
      type: VALIDATE_INVOICE_SUCCESS,
      invoice: deriveRawToInvoice(response.invoice),
    });
  };
}

export function checkCustomerCode(code) {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.invoice.check + code,
      method: 'GET',
      mockData: CheckCustomerCodeMock,
    });

    if (!response || response?.isValid === undefined) {
      dispatch({
        type: CHECK_CUSTOMER_CODE_FAILURE,
        err: 'ERROR.ERROR',
      });
      return;
    }

    dispatch({
      type: CHECK_CUSTOMER_CODE_SUCCESS,
      isValid: !!response.isValid,
    });
  };
}

export function createPaymentRequest(values) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IS_PROCESSING,
    });

    const response = await fetchApi({
      url: configs.api.invoice.create,
      method: 'POST',
      param: values,
      mockData: CreatePaymentRequestMock,
    });

    if (!response || response?.success === undefined) {
      dispatch({
        type: CREATE_INVOICE_FAILURE,
        err: 'ERROR.CREATE_INVOICE_FAIL',
      });
      toast({ message: response.err || 'ERROR.CREATE_INVOICE_FAIL', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: CREATE_INVOICE_SUCCESS,
    });
    toast({ message: 'MESSAGE.CREATE_INVOICE_SUCCESS', color: COLORS.SUCCESS });
  };
}

export function submitCardPayment(param) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.PAYMENT',
    });

    const response = await fetchApi({
      url: configs.api.transaction.pay,
      method: 'POST',
      param,
      mockData: PayCardMock,
    });

    if (!response || response?.success === undefined) {
      dispatch({
        type: PAYMENT_FAILURE,
        err: 'ERROR.PAYMENT_FAIL',
      });
      toast({ message: response.err || 'ERROR.PAYMENT_FAIL', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: PAYMENT_SUCCESS,
    });
  };
}

export function sendInvoices(users) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.SENDING',
    });

    const response = await fetchApi({
      url: configs.api.invoice.sendInvoices,
      param: {
        users: JSON.stringify(users),
      },
      method: 'POST',
    });

    if (!response?.success) {
      dispatch({
        type: SEND_INVOICES_FAILURE,
        err: 'ERROR.SEND_INVOICES',
      });
      // FRONT-END function
      toast({ message: response.err || 'ERROR.SEND_INVOICES', color: COLORS.DANGER });
      return;
    }

    dispatch({
      type: SEND_INVOICES_SUCCESS,
      numSuccess: response.numSuccess || 0,
      numFailure: response.numFailure || 0,
    });

    // FRONT-END function
    // toast({ message: 'MESSAGE.SEND_INVOICE_SUCCESS', color: COLORS.SUCCESS, cssClass: 'small' });
  };
}

export function resetSuccess(): InvoiceActionTypes {
  return { type: RESET_SUCCESS };
}

export function dehydrate(): InvoiceActionTypes {
  return { type: RESET_ALL };
}
