import LoadingModel from '$gbusiness/models/loading';

export const LOADING = 'LOADING';

// Action Model
export interface LoadingType {
  // loading reducer
  type: typeof LOADING;
  loadingText?: string;
  err?: string;
}

export type LoadingActionTypes = LoadingType;

export const LOADING_INIT_STATE: LoadingModel = {
  isLoading: false,
  loadingText: 'PROGRESS.LOADING',
  hasError: false,
  err: '',
};
