import styled from 'styled-components';
import { Div } from '.';
import { SPACE, ENUMS } from './enums';
import { mobileStyle, deriveColWidth } from './utils';

export const Row = styled(Div)`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
`;

export const ColAuto = styled(Div)`
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: ${props => props.padding || SPACE.DEF};

  ${mobileStyle(`
      width: 100%;
  `)}
`;

export const Col = styled(Div)`
  position: relative;
  width: 100%;
  min-height: 1px;
  margin: 0;
  padding: ${props => props.padding || SPACE.DEF};

  @media screen and (min-width: ${p => p.breakpoint || ENUMS.MOBILE_WIDTH}) {
    width: ${props => deriveColWidth(props.gridSize) || 100};
    -webkit-box-flex: 0;
    -ms-flex: 0 0 ${props => deriveColWidth(props.gridSize) || 100};
  }
}
`;
