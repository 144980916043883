import React from 'react';

import { configs } from '$configs';
import { SORT, ROUTES, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import mockTransactions from '$business/mocks/getTransactionsShort.json';
import { formatDatetime } from '$gbusiness/helpers/date';
import intl from '$intl';
import { deriveRawToTransactions } from '$business/models/transactions';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: e => `${e.firstName} ${e.lastName} ${e.email}`,
  selectedValue: e => e.firstName,
  onSelect: (e, history) => {
    history.push(`${ROUTES.USERS}/${e.userId}`);
  },
};

export const TABLE_CONFIG = {
  endpoint: configs.api.invoice.list,
  isPublic: false,
  mockData: mockTransactions,
  deriveToModel: deriveRawToTransactions,
  color: COLORS.SECONDARY,
  pageSize: 50,
  cellPadding: `3px`,
  isStripped: true,
  minWidth: 960,
  shouldVirtualize: false,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 40,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARDHOLDER',
    value: 'cardHolder',
    width: 240,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARD_TYPE',
    value: 'cardType',
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.AMOUNT',
    value: 'amount',
    component: row => <>{intl('PRICE', { amount: row.amount })}</>,
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.CARD_NUMBER',
    value: 'last4',
    component: row => <>############{row.last4}</>,
    width: 250,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.EXPIRY',
    value: 'expiry',
    width: 100,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.PAYMENT_RESULT',
    value: 'paymentResult',
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.REFUND_STATUS',
    value: 'refundStatus',
    sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.TIME',
    value: 'datetime',
    component: row => <>{row.datetime ? formatDatetime(row.datetime) : ''}</>,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.ATUH',
    value: 'atuh',
    width: 140,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.REF_NUM',
    value: 'refNum',
    width: 70,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.USER',
    value: 'userName',
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.TRANSACTIONS.COLS.ACTION',
    value: '',
    align: 'center',
    component: (row, actions) => {
      return <MoreHorizIcon className="pointer" onClick={() => actions.clickAction(row)} />;
    },
    width: 100,
  },
];

export default TABLE;
