import _ from 'lodash';
import React from 'react';
import { parseISO } from 'date-fns';
import { FastField } from 'formik';
import { TextFieldProps } from '@material-ui/core/TextField';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import { configs } from '$configs';
import intl from '$gintl';

import { BSLabel } from './styles';
import { FormControl, InputAdornment } from '@material-ui/core';
import { DateTimePicker, DatePicker } from '@material-ui/pickers';
import { InnerAdornment } from '$gstyles/input';

interface DateInputProps {
  label?: string;
  name: string;
  formik?: any;
  size?: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  shrink?: boolean;
  position?: TextFieldProps['variant'];
  minDate?: string;
  maxDate?: string;
  format?: string;
  defaultValue?: string;
  handleChange?: (e) => void;
}

const DateInput: React.FC<DateInputProps> = properties => {
  const {
    label = '',
    name = '',
    formik = {},
    size = configs.display?.inputSize,
    readonly = false,
    disabled = false,
    placeholder = '',
    shrink = false,
    position = undefined,
    minDate = parseISO('2010'),
    maxDate = new Date(),
    format = 'M/d/yyyy hh:mm a',
  } = properties;
  const onChange = selectedDate => {
    //formik.setFieldValue(name, dateFormat(selectedDate, 'M/d/yyyy hh:mm a'));
    formik.setFieldValue(name, selectedDate);
  };

  // const onBlur = e => {
  //   // any blur handling goes here
  //   if (formik.handleBlur) formik.handleBlur(e);
  // };

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const inputSize = size === 'small' ? 'small' : 'medium';
  const labelProps = position
    ? {
        label: intl(label),
        InputLabelProps: {
          ...(shrink && { shrink }),
        },
      }
    : {};
  const variant = position || 'outlined';

  return (
    <>
      <FastField name={name}>
        {({ form }) => {
          return (
            <FormControl size={inputSize} fullWidth>
              {!position && <BSLabel className="bs-label">{intl(label)}</BSLabel>}
              <DateTimePicker
                clearable
                {...labelProps}
                fullWidth
                readOnly={readonly}
                disabled={disabled}
                name={name}
                placeholder={intl(placeholder) || undefined}
                onChange={onChange}
                size={inputSize}
                value={_.get(form.values, name) || null}
                inputVariant={variant}
                error={hasError}
                format={format}
                minDate={minDate}
                maxDate={maxDate}
                helperText={errorMsg}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InnerAdornment>
                        <CalendarTodayIcon />
                      </InnerAdornment>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          );
        }}
      </FastField>
    </>
  );
};

export default DateInput;
