import { ROUTES } from '$gbusiness/enums';
import { UserScreen, UsersScreen } from '$gbox/screens';
import RouteModel from '$gbusiness/models/route';

import {
  HomeScreen,
  InvoiceListScreen,
  PaymentScreen,
  SendLinkScreen,
  TransactionsScreen,
} from '$product/screens';

const { USERS } = ROUTES;

// IMPORTS FROM GBOX

export const HOME = '/home';
export const INVOICES = '/invoices';
export const TRANSACTIONS = '/transactions';
export const PAYMENT = '/payment';
export const SEND_LINK = '/sendlink';

export const LIST: Array<RouteModel> = [
  {
    title: 'MENU.TRANSACTIONS',
    path: TRANSACTIONS,
    component: TransactionsScreen,
  },
  {
    path: SEND_LINK,
    component: SendLinkScreen,
    exact: true,
  },
  // {
  //   title: 'MENU.INVOICES',
  //   path: INVOICES,
  //   component: InvoiceListScreen,
  // },
  // {
  //   title: 'MENU.HOME',
  //   path: HOME,
  //   component: HomeScreen,
  // },
  // {
  //   title: 'MENU.USERS',
  //   path: USERS,
  //   component: UsersScreen,
  // },
  {
    path: USERS,
    param: ':userId',
    component: UserScreen,
    exact: true,
  },
];

export const PUBLIC_LIST = [
  ...ROUTES.PUBLIC_LIST,
  {
    path: PAYMENT,
    param: ':uuid',
    component: PaymentScreen,
    exact: true,
  },
];

export const MENU = LIST.filter(R => R.title);
export const DEFAULT_APP_ROUTE = LIST[0].path;
