import _ from 'lodash';
import React from 'react';
import { IonItem, IonLabel, IonInput, IonIcon } from '@ionic/react';
import { FastField, ErrorMessage } from 'formik';

import { MASKS, MODES, TYPES, InputModeType } from '$gbusiness/enums/input';
import intl from '$gintl';
import { mask } from '$ghelpers';
import { Styles } from '$gstyles';
import { configs } from '$configs';
import { Postfix } from './styles';

interface TextInputProps {
  icon?: string;
  label?: string;
  postfix?: string;
  name: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  position?: string;
  maskType?: string | undefined;
  type?: string;
  maxLength?: number | undefined;
  inputMode?: InputModeType;
  formik?: any;
  enterToSubmit?: boolean;
  handleBlur: Function;
  handleChange?: (e) => void;
  handleKeyPress?: Function;
}

const defaultProps: TextInputProps = {
  icon: '',
  label: '',
  postfix: '',
  name: '',
  readonly: false,
  disabled: false,
  placeholder: '',
  inputMode: 'text',
  position: '',
  formik: {},
  enterToSubmit: false,
  type: TYPES.TEXT,
  handleChange: () => {},
  handleBlur: () => {},
  handleKeyPress: () => {},
};

const getType = inputType => {
  return inputType || 'text';
};

const getModeType = modeType => {
  return modeType || 'text';
};

const getPosition = position => {
  return position || configs.defaultLabelPosition;
};

const TextInput: React.FC<TextInputProps> = ({
  icon,
  label,
  postfix,
  name,
  readonly,
  disabled,
  placeholder,
  position,
  inputMode,
  type,
  maxLength,
  maskType,
  formik,
  enterToSubmit,
  handleChange,
  handleBlur,
  handleKeyPress,
  ...props
}) => {
  const maskInput = value => {
    switch (maskType) {
      case MASKS.PHONE:
        formik.setFieldValue(name, mask.phone(value));
        break;
      case MASKS.SSN:
        formik.setFieldValue(name, mask.ssn(value));
        break;
      default:
        break;
    }
  };

  const onKeyPress = e => {
    const charCode = e.which ? e.which : e.keyCode;
    if (enterToSubmit && charCode === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
    if (type === TYPES.NUMBER || inputMode === MODES.NUMERIC) {
      if (type !== TYPES.NUMBER && charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
    if (handleKeyPress) handleKeyPress(e);
    return true;
  };

  const onKeyUp = e => {
    maskInput(e.target.value);
  };

  const onChange = e => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const onBlur = e => {
    // any blue handling goes here
    if (formik.handleBlur) formik.handleBlur(e);
    if (handleBlur) handleBlur(e);
  };

  let derivedMaxLength = maxLength;
  if (maskType === MASKS.PHONE) derivedMaxLength = 12;
  else if (maskType === MASKS.SSN) derivedMaxLength = 11;

  let pattern;
  if (inputMode === MODES.NUMERIC) pattern = '[0-9]*';

  const inputLabel = icon !== '' ? null : <IonLabel position={getPosition(position)}>{intl(label)}</IonLabel>;
  const inputPlaceholder = icon !== '' ? intl(label) : placeholder;

  return (
    <>
      <FastField name={name}>
        {({ form }) => (
          <>
            <IonItem>
              {icon !== '' && <IonIcon slot="start" icon={icon} />}
              {inputLabel}
              <ErrorMessage name={name}>
                {e => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
              </ErrorMessage>
              <IonInput
                {...props}
                inputmode={getModeType(inputMode)}
                type={getType(type)}
                name={name}
                readonly={readonly}
                disabled={disabled}
                onKeyDown={onKeyPress}
                onKeyUp={onKeyUp}
                pattern={pattern}
                maxlength={derivedMaxLength}
                placeholder={inputPlaceholder || undefined}
                onBlur={onBlur}
                onInput={onChange}
                value={_.get(form.values, name) || ''}></IonInput>
              {postfix && <Postfix>{intl(postfix)}</Postfix>}
            </IonItem>
          </>
        )}
      </FastField>
    </>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
