import styled from 'styled-components';
import { Div, Mixins } from '$gstyles';

export const Wrapper = styled(Div)``;

export const EmptyWrapper = styled(Div)`
  ${Mixins.vcenter}
  text-align: center;
`;

export const Circle = styled(Div)`
  ${Mixins.vcenter}
  width: 461px;
  height: 461px;
  background: #f2f2f2;
  border-radius: 50%;
`;
