import styled from 'styled-components';

import { ItemWrapper, SPACE } from '$gstyles';

export const Wrapper = styled(ItemWrapper)`
  ion-item.item ion-label {
    &.sc-ion-label-md-h,
    &.sc-ion-label-md-s {
      --color: var(--ion-label-color);
    }
  }

  margin-left: -${SPACE.MEDIUM};
  margin-right: -${SPACE.MEDIUM};

  .MuiFormControl-root {
    margin-top: ${SPACE.MEDIUM};
  }
  .MuiInputBase-root {
    font-size: 1.5rem;
  }

  /* .MuiInputBase-input {
    padding: ${SPACE.LARGE} ${SPACE.LARGE};
  } */
`;
