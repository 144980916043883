import InvoiceModel from '$business/models/invoice';

export const IS_PROCESSING = 'IS_SUBMITTING';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE';
export const CLEAN_INVOICES = 'CLEAN_INVOICES';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICES_SUCCESS';
export const CREATE_INVOICE_FAILURE = 'CREATE_INVOICES_FAILURE';
export const SEND_INVOICES_SUCCESS = 'SEND_INVOICES_SUCCESS';
export const SEND_INVOICES_FAILURE = 'SEND_INVOICES_FAILURE';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAILURE = 'RESET_FAILURE';
export const CHECK_CUSTOMER_CODE_SUCCESS = 'CHECK_CUSTOMER_CODE_SUCCESS';
export const CHECK_CUSTOMER_CODE_FAILURE = 'CHECK_CUSTOMER_CODE_FAILURE';
export const VALIDATE_INVOICE_SUCCESS = 'VALIDATE_INVOICE_SUCCESS';
export const VALIDATE_INVOICE_FAILURE = 'VALIDATE_INVOICE_FAILURE';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';
export const RESET_ALL = 'RESET_ALL';
export const RESET_CUSTOMER_CODE = 'RESET_CUSTOMER_CODE';

// Action Types
export interface typeOnlyType {
  type:
    | typeof FETCH_INVOICES_SUCCESS
    | typeof CLEAN_INVOICES
    | typeof CREATE_INVOICE_SUCCESS
    | typeof UPDATE_INVOICE_SUCCESS
    | typeof RESET_SUCCESS
    | typeof RESET_ALL
    | typeof RESET_CUSTOMER_CODE
    | typeof IS_PROCESSING
    | typeof PAYMENT_SUCCESS;
}

export interface failureType {
  type:
    | typeof FETCH_INVOICES_FAILURE
    | typeof CHECK_CUSTOMER_CODE_FAILURE
    | typeof VALIDATE_INVOICE_FAILURE
    | typeof CREATE_INVOICE_FAILURE
    | typeof UPDATE_INVOICE_FAILURE
    | typeof RESET_FAILURE;
  err: string;
}

export interface CheckCustomerCodeSuccessType {
  type: typeof CHECK_CUSTOMER_CODE_SUCCESS;
  isValid: boolean;
}

export interface SendInvoicesSuccessType {
  type: typeof SEND_INVOICES_SUCCESS;
  numSuccess: number;
  numFailure: number;
}

export interface ValidateInvoiceSuccessType {
  type: typeof VALIDATE_INVOICE_SUCCESS;
  invoice: InvoiceModel;
}

export interface InvoiceReducerType {
  invoice: InvoiceModel | null;
  isValidCustomerCode: boolean | null;
  numSuccess: number;
  numFailure: number;
  isProcessing: boolean;
  isFinished: boolean;
}

export type InvoiceActionTypes =
  | typeOnlyType
  | failureType
  | SendInvoicesSuccessType
  | CheckCustomerCodeSuccessType
  | ValidateInvoiceSuccessType;

export const INVOICE_INIT_STATE: InvoiceReducerType = {
  invoice: null,
  isValidCustomerCode: null,
  numSuccess: 0,
  numFailure: 0,
  isProcessing: false,
  isFinished: false,
};
