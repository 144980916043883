import styled from 'styled-components';
import { BOX_SIZE, SPACE } from './enums';
import Div from './div';

const commonProps = props => `
  padding: ${props.padding || SPACE.LARGE};
  border: ${props.border || 'none'};
  border-width: ${props.borderWidth || 'auto'};
  border-radius: ${props.borderRadius || '0px'};
  margin: ${props.margin || SPACE.LARGE} auto;
  text-align: center;
`;

const Box = {
  XSMALL: styled(Div)`
    padding: ${props => props.padding || SPACE.LARGE};
    margin: ${props => props.margin || SPACE.LARGE};
    border: ${props => props.border};
    border-width: ${props => props.borderWidth || 'auto'};
    border-radius: ${props => props.borderRadius};
    max-width: ${BOX_SIZE.XSMALL};
  `,
  SMALL: styled(Div)`
    ${props => commonProps(props)}
    max-width: ${BOX_SIZE.SMALL};
  `,
  MEDIUM: styled(Div)`
    ${props => commonProps(props)}
    max-width: ${BOX_SIZE.MEDIUM};
  `,
  LARGE: styled(Div)`
    ${props => commonProps(props)}
    max-width: ${BOX_SIZE.LARGE};
  `,
  XLARGE: styled(Div)`
    ${props => commonProps(props)}
    max-width: ${BOX_SIZE.XLARGE};
  `,
  MOBILE: styled(Div)`
    ${props => commonProps(props)}
    max-width: ${BOX_SIZE.MOBILE};
  `,
  FULL: styled(Div)`
    ${props => commonProps(props)}
    width: ${BOX_SIZE.FULL};
    height: ${BOX_SIZE.FULL};
  `,
};

export default Box;
