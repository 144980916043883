import styled from 'styled-components';
import { SPACE, ENUMS } from './enums';
import { Div } from '$gstyles';
import { fullScreen } from './mixins';

export const Wrapper = styled(Div)`
  padding: ${props => props.padding || SPACE.LARGE};
  max-width: ${props => props.maxWidth || 'none'};
  flex: 1;

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    padding: ${props => props.padding || SPACE.LARGE};
  }
`;

export const WrapperDesktop = styled(Div)`
  @media screen and (min-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    padding: ${props => props.padding || SPACE.LARGE};
    max-width: ${props => props.maxWidth || 'none'};
  }
`;

export const ItemWrapper = styled(Div)`
  margin-left: -${props => props.padding || SPACE.LARGE};
  margin-right: -${props => props.padding || SPACE.LARGE};

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    margin-left: -${props => props.padding || SPACE.LARGE};
    margin-right: -${props => props.padding || SPACE.LARGE};
  }
`;

export const Flex = styled(Div)`
  display: flex;
  align-items: center;
`;

export const FlexWrapper = styled(Div)`
  display: flex;
  ${fullScreen}
  overflow: hidden;
  flex-direction: column;
`;

export const ListContainer = styled(Div)`
  &&.virtualized {
    overflow: hidden;
    height: 100%;
  }
`;

export const Mobile = styled(Div)`
  display: none;

  @media screen and (max-width: ${ENUMS.MOBILE_WIDTH}) {
    display: block;
  }
`;

export const Desktop = styled(Div)`
  display: block;

  @media screen and (max-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    display: none;
  }
`;
