import styled from 'styled-components';
import { SPACE } from './enums';
import { Inline } from './div';

const { DEF } = SPACE;

const Span = {
  All: styled(Inline)`
    padding: ${props => props.padding || DEF};
    margin: ${props => props.margin || DEF};
    display: inline-block;
  `,
  Right: styled(Inline)`
    padding-right: ${props => props.padding || DEF};
    margin-right: ${props => props.margin || DEF};
    display: inline-block;
  `,
  Left: styled(Inline)`
    padding-left: ${props => props.padding || DEF};
    margin-left: ${props => props.margin || DEF};
    display: inline-block;
  `,
  Top: styled(Inline)`
    padding-top: ${props => props.padding || DEF};
    margin-top: ${props => props.margin || DEF};
    display: inline-block;
  `,
  Bottom: styled(Inline)`
    padding-bottom: ${props => props.padding || DEF};
    margin-bottom: ${props => props.margin || DEF};
    display: inline-block;
  `,
  Hor: styled(Inline)`
    padding: ${DEF} ${props => props.padding || DEF};
    margin: ${DEF} ${props => props.margin || DEF};
    display: inline-block;
  `,
  Ver: styled(Inline)`
    padding: ${props => props.padding || DEF} ${DEF};
    margin: ${props => props.margin || DEF} ${DEF};
    display: inline-block;
  `,
  Free: styled(Inline)`
    padding: ${props => props.padding || DEF};
    margin: ${props => props.margin || DEF};
    display: inline-block;
    width: ${props => props.width || DEF}px;
    height: ${props => props.height || DEF}px;
  `,
};

export default Span;
