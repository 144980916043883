import React from 'react';
import {
  IonButtons,
  IonFooter,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
} from '@ionic/react';
import { Text } from '$gintl';

interface ModalProps {
  show: boolean;
  title?: string;
  className?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ show, title = '', children, className, footer, onClose }) => {
  return (
    <IonModal isOpen={show} cssClass={className} swipeToClose={true} onDidDismiss={onClose}>
      <>
        {title && (
          <IonHeader>
            <IonToolbar>
              <IonTitle>
                <Text k={title} />
              </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={onClose}>
                  <Text k="BUTTON.CLOSE" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent fullscreen>{children}</IonContent>
        {footer && (
          <IonFooter>
            <IonToolbar>{footer}</IonToolbar>
          </IonFooter>
        )}
      </>
    </IonModal>
  );
};

export default Modal;
