import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { IconButton } from '@material-ui/core';

import { IconInputWrapper, InnerAdornment, OuterAdornment } from '$styles/input';
import { Inline, SPACE, Space } from '$gstyles';

interface LeftComponentProps {
  filter: {
    query: string;
    dateStart: Date | null;
    dateEnd: Date | null;
  };
  onFilterChange: Function;
}

const LeftComponent: React.FC<LeftComponentProps> = ({ filter, onFilterChange }) => {
  const onQueryChange = e => {
    onFilterChange({ ...filter, query: e.target.value });
  };
  const onDateStartChange = e => {
    onFilterChange({ ...filter, dateStart: e });
  };
  const onDateEndChange = e => {
    onFilterChange({ ...filter, dateEnd: e });
  };

  return (
    <>
      <IconInputWrapper>
        <TextField
          label=""
          variant="outlined"
          placeholder="test"
          size="small"
          value={filter.query}
          onChange={onQueryChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    console.log('SEARCH NOW');
                  }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
      <Space.Hor margin={SPACE.MEDIUM} />
      <IconInputWrapper>
        <DateTimePicker
          clearable
          label=""
          size="small"
          inputVariant="outlined"
          value={filter.dateStart}
          onChange={onDateStartChange}
          format="M/d/yyyy hh:mm a"
          style={{ width: '200px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InnerAdornment>
                  <CalendarTodayIcon />
                </InnerAdornment>
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
      <Inline className="flexCenter">to</Inline>
      <IconInputWrapper>
        <DateTimePicker
          clearable
          label=""
          size="small"
          inputVariant="outlined"
          value={filter.dateEnd}
          onChange={onDateEndChange}
          format="M/d/yyyy hh:mm a"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <InnerAdornment>
                  <CalendarTodayIcon />
                </InnerAdornment>
                <OuterAdornment
                  onClick={e => {
                    e.stopPropagation();
                    console.log('SEARCH NOW');
                  }}>
                  <SearchIcon />
                </OuterAdornment>
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
    </>
  );
};

export default LeftComponent;
