import { COLOR } from '$gstyles';
import { configs } from '$configs';
import { SORT } from '../enums';

export interface TableStylesModel {
  color?: string;
  isStripped?: boolean;
  cellPadding?: string;
  minWidth?: number;
  maxWidth?: number;
  headerHeight?: number;
  rowHeight?: number;
  checkWidth?: number;
  additionalHeight?: number;
}

export interface DataSourceModel {
  endpoint?: string;
  mockData?: any;
  fixedData?: any;
  isPublic?: boolean;
  pageSize?: number;
  defaultPage?: number;
  defaultSortKey?: string;
  defaultSortOrder?: SORT.types;
  shouldVirtualize?: boolean;
  deriveToModel?: Function;
}

export interface TableDisplayModel {
  totalDataSize?: number;
  pageSize?: number;
  page?: number;
  sortKey?: string;
  sortOrder?: SORT.types;
  isSelectable?: boolean;
}

export const defaultStyles: TableStylesModel = {
  color: COLOR.PRIMARY,
  isStripped: true,
  cellPadding: 'inherit',
  minWidth: 800,
};

export const defaultDataSource = {
  endPoint: '',
  isPublic: false,
  pageSize: configs.display.pageSize || 20,
  defaultPage: 1,
  defaultSortOrder: SORT.ASC,
  shouldVirtualize: false,
};

export const defaultDisplay = {
  totalDataSize: 0,
  page: 1,
  pageSize: configs.display.pageSize || 20,
  sortKey: '',
  sortOrder: SORT.ASC,
  isSelectable: false,
};
