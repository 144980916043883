import pConfigs from '$product/config/configs.json';
import pFeatures from '$product/config/features.json';
import gConfigs from '$gbox/config/configs.json';
import gFeatures from '$gbox/config/features.json';

const configs = {
  ...gConfigs,
  ...pConfigs,
  api: {
    ...gConfigs.api,
    ...pConfigs.api,
  },
  localStorage: [...gConfigs.localStorage, ...pConfigs.localStorage],
  display: {
    ...gConfigs.display,
    ...pConfigs.display,
  },
};

const features = {
  ...gFeatures,
  ...pFeatures,
};

export { configs, features };
