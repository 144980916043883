import React from 'react';

import { configs } from '$configs';
import { SORT, ROUTES, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { today } from '$ghelpers/date';
import { COLOR, Div } from '$gstyles';
import { Chip } from '@material-ui/core';
import { IonIcon } from '@ionic/react';
import { mail, ellipsisHorizontal } from 'ionicons/icons';
import mockInvoices from '$business/mocks/getInvoicesShort.json';
import { deriveRawToInvoice } from '$business/models/invoice';

export const AUTO_COMPLETE = {
  endpoint: configs.api.users.search,
  renderSuggestion: e => `${e.firstName} ${e.lastName} ${e.email}`,
  selectedValue: e => e.firstName,
  onSelect: (e, history) => {
    history.push(`${ROUTES.USERS}/${e.userId}`);
  },
};

export const TABLE_CONFIG = {
  endpoint: configs.api.invoice.list,
  deriveToModel: deriveRawToInvoice,
  isPublic: false,
  mockData: mockInvoices,
  color: COLORS.SECONDARY,
  pageSize: 20,
  cellPadding: `10px`,
  isStripped: true,
  minWidth: 960,
  shouldVirtualize: true,
  virtualized: {
    rowHeight: 36,
    headerHeight: 60,
    checkWidth: 60,
    threshold: 10,
    additionalHeight: 40,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

const TABLE: Array<CellModel> = [
  {
    label: 'SCREEN.INVOICE.COLS.DATE',
    value: 'dueDate',
    sortable: SORT.ASC,
    width: 240,
  },
  {
    label: 'SCREEN.INVOICE.COLS.SEQ',
    value: 'invoiceId',
    sortable: SORT.ASC,
    width: 80,
  },
  {
    label: 'SCREEN.INVOICE.COLS.PAST_DUE',
    value: 'dueDate',
    component: row => {
      const isPastDue = row.dueDate < today();
      const color = isPastDue ? COLOR.DANGER : COLOR.SUCCESS;
      const text = isPastDue ? 'Past Due' : 'No';
      return <Div color={color}>{text}</Div>;
    },
    sortable: SORT.ASC,
    width: 150,
  },
  {
    label: 'SCREEN.INVOICE.COLS.BILL_TYPE',
    value: 'invoiceType',
    component: row => {
      const color =
        row.invoiceType === 'Item Sales' ? 'default' : row.invoiceType === 'PPI' ? 'secondary' : 'primary';
      return <Chip variant="outlined" color={color} size="small" label={row.invoiceType} />;
    },
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.BILL_NUMBER',
    value: 'invoiceNumber',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.CUSTOMER_CODE',
    value: 'customerCode',
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.EMAIL',
    value: 'email',
    sortable: SORT.ASC,
    width: 350,
  },
  {
    label: 'SCREEN.INVOICE.COLS.SEND_BILL',
    value: '',
    component: (row, action) => {
      return (
        <>
          <IonIcon icon={mail} onClick={() => action.sendBill(row)} />
          Send
        </>
      );
    },
    width: 200,
  },
  {
    label: 'SCREEN.INVOICE.COLS.ACTION',
    value: '',
    align: 'center',
    component: (row, action) => {
      return <IonIcon icon={ellipsisHorizontal} onClick={e => action.clickAction(e.nativeEvent, row)} />;
    },
    width: 100,
  },
];

export default TABLE;
