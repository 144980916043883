import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonImg,
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';

import { configs } from '$configs';
import { ROUTES } from '$business/enums';
import intl, { Text } from '$intl';

import { Wrapper } from './styles';
import { AppVersion } from '$gcomponents/reusables';
import PopOver from '$gcomponents/reusables/popOver';
import { Button } from '@material-ui/core';
import { SPACE, Space } from '$gstyles';

interface MenuProps extends RouteComponentProps {
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
}

// <SplitButton defaultIndex={1} onClick={index => console.log(index)} options={defaultOptions} />

const Menu: React.FC<MenuProps> = React.memo(
  ({ currentRoute, isLoggedIn, onLogout, ...rest }) => {
    const history = useHistory();

    const navigate = route => {
      if (currentRoute === route) return;
      history.push(route);
    };

    const MakePaymentButton = (
      <PopOver
        component={
          <Button variant="outlined" fullWidth>
            + New
          </Button>
        }>
        <Space.All padding={SPACE.MEDIUM} className="payment-options">
          <div>
            <Button onClick={() => navigate(ROUTES.SEND_LINK)}>{intl('MENU.MAKE_PAYMENT')}</Button>
          </div>
          <div>
            <Button onClick={() => navigate(ROUTES.SEND_LINK)}>{intl('MENU.SCHEDULE_PAYMENT')}</Button>
          </div>
          <div>
            <Button onClick={() => navigate(ROUTES.SEND_LINK)}>{intl('MENU.SEND_LINK')}</Button>
          </div>
        </Space.All>
      </PopOver>
    );
    return (
      <Wrapper
        contentId="main"
        menuId="start"
        disabled={!isLoggedIn}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {configs.display.layout !== 'FIXED_HEADER' && (
          <IonHeader>
            <IonToolbar>
              <IonImg class="logo" src={configs.display.logoPath} />
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          {MakePaymentButton}
          <IonList>
            {ROUTES.MENU.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={currentRoute === appPage.path ? 'selected pointer' : 'pointer'}
                    routerLink={`${appPage.path}${appPage.defaultId || ''}`}
                    routerDirection="none"
                    lines="none"
                    detail={false}>
                    <IonLabel>
                      <Text k={appPage?.title} />
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
            <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
              <IonLabel>
                <Text k="MENU.LOG_OUT" />
              </IonLabel>
            </IonItem>
          </IonList>
          <div>
            <AppVersion />
          </div>
        </IonContent>
      </Wrapper>
    );
  },
  (pp, np) => pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn,
);

export default withRouter(Menu);
