import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import MuiModal from '$gcomponents/reusables/modal/material';

import { AppModel } from '$business/redux';
import { checkCustomerCode, createPaymentRequest, dehydrate } from '$business/redux/invoice/actions';
import intl, { Text } from '$intl';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';

import PaymentRequestModel, { defaultPaymentRequest } from '$business/models/paymentRequest';
import PAYMENT_REQUEST_FORM from './PaymentRequestForm';
import { SPACE, Space } from '$gstyles';

interface PaymentRequestModalProps {
  paymentRequest?: PaymentRequestModel;
  onClose: () => void;
  initialValues?: any;
  show: boolean;
  fullScreen?: boolean;
}

const PaymentRequestModal: React.FC<PaymentRequestModalProps> = ({
  onClose,
  paymentRequest = defaultPaymentRequest,
  fullScreen = false,
  show,
}) => {
  const dispatch = useDispatch();
  const invoice = useSelector((state: AppModel) => state.invoice);
  const formRef = useRef<any>();

  const derivedInitialValues = input.deriveFormInitialValues(PAYMENT_REQUEST_FORM, paymentRequest);
  const validateForm = values => input.validateError(PAYMENT_REQUEST_FORM, values);
  const onSubmit = values => {
    dispatch(createPaymentRequest(values));
  };

  useEffect(() => {
    if (!invoice.isFinished) return;
    dispatch(dehydrate());
    if (formRef?.current && formRef.current.resetForm) formRef.current.resetForm();
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.isFinished]);

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize={true}
      initialValues={derivedInitialValues}
      validate={validateForm}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <MuiModal
            open={show}
            onClose={onClose}
            fullScreen={fullScreen}
            title={intl('SCREEN.PAYMENT_REQUEST.TITLE')}
            footer={
              <Button
                disabled={!(formik.isValid && invoice.isValidCustomerCode)}
                color="primary"
                isLoading={invoice.isProcessing}
                loadingText={intl('SCREEN.PAYMENT_REQUEST.SUBMIT_BUTTON_LOADING')}
                fullWidth
                variant="contained"
                onClick={formik.handleSubmit}>
                {intl('SCREEN.PAYMENT_REQUEST.SUBMIT_BUTTON')}
              </Button>
            }>
            <Form>
              <Text k="SCREEN.PAYMENT_REQUEST.SUBTITLE" />
              <Space.Ver padding={SPACE.SMALL} />
              <FormSection
                FORM={PAYMENT_REQUEST_FORM}
                formik={formik}
                callback={value => {
                  dispatch(checkCustomerCode(value));
                }}
              />
            </Form>
            {invoice.isValidCustomerCode && <div>{intl('MESSAGE.VALID_CUSTOMER_CODE')}</div>}
            {invoice.isValidCustomerCode === false && <div>{intl('MESSAGE.INVALID_CUSTOMER_CODE')}</div>}
          </MuiModal>
        );
      }}
    </Formik>
  );
};

export default PaymentRequestModal;
