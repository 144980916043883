import React from 'react';
import { connect } from 'react-redux';
import { IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES as GROUTES } from '$gbusiness/enums';
import { ROUTES } from '$business/enums';

import { configs, features } from '$configs';
import Menu from '$components/sideMenu';

import AppOutlet from '$product/_init/appOutlet';

interface RouterProps {
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
}

const Router: React.FC<RouterProps> = React.memo(
  ({ isLoggedIn, currentRoute, onLogout }) => {
    const redirectRoute = isLoggedIn ? ROUTES.DEFAULT_APP_ROUTE : GROUTES.DEFAULT_PUBLIC_ROUTE;
    // const currentRoute = window.location.pathname;
    return (
      <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
        {
          <IonSplitPane contentId="main" when={`(min-width: ${configs.display.splitPaneThreshold})`}>
            {features.useMenu && (
              <Menu currentRoute={currentRoute} onLogout={onLogout} isLoggedIn={isLoggedIn} />
            )}
            <AppOutlet isLoggedIn={isLoggedIn} />
          </IonSplitPane>
        }
        <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
      </IonReactRouter>
    );
  },
  (pp, np) => pp.isLoggedIn === np.isLoggedIn && pp.currentRoute === np.currentRoute,
);

const mapStateToProps = state => ({
  isLoggedIn: state.localStorage.accessToken !== '',
  currentRoute: state.routing.currentRoute,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
