export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const DANGER = 'danger';
export const DARK = 'dark';
export const MEDIUM = 'medium';
export const LIGHT = 'light';
export const TRANSPARENT = 'transparent';
