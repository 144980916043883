import React from 'react';
import { Formik, Form } from 'formik';

import { input } from '$ghelpers';
import { Button, Text } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { Box, SPACE } from '$gstyles';

import LOGIN_FORM from '$gbox/screens/loginScreen/loginForm';

interface LoginFormProps {
  onSubmit: Function;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const validateForm = values => {
    console.log(values);
    return input.validateError(LOGIN_FORM, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ state: '' }}
      validate={validateForm}
      onSubmit={values => {
        onSubmit(values);
      }}>
      {formik => (
        <Box.MEDIUM padding={SPACE.SMALL}>
          <Form>
            <FormSection title="SCREEN.LOGIN.FORM_TITLE" FORM={LOGIN_FORM} formik={formik} marginBottom="0" />
            <Button fullWidth onClick={formik.handleSubmit} disabled={!(formik.isValid && formik.dirty)}>
              <Text k="BUTTON.LOGIN" />
            </Button>
          </Form>
        </Box.MEDIUM>
      )}
    </Formik>
  );
};

export default LoginForm;
