import React from 'react';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gcomponents/utils';
import { HeaderTitle, Text } from '$gcomponents/primitives';
import { Row, Col, SPACE, Space } from '$gstyles';
import { Wrapper } from './styles';

interface FormSectionProps {
  FORM: Array<InputRowModel>;
  formik: any;
  children?: React.ReactNode;
  inputType?: string;
  title?: string;
  isHidden?: boolean;
  marginBottom?: string;
  callback?: Function;
}

const FormSection: React.FC<FormSectionProps> = ({
  FORM,
  formik,
  title,
  children,
  inputType,
  isHidden,
  marginBottom = SPACE.XLARGE,
  callback,
}) => {
  if (isHidden) return null;

  return (
    <>
      {title && (
        <HeaderTitle>
          <Text k={title} />
        </HeaderTitle>
      )}
      <Space.Bottom margin={marginBottom}>
        <Wrapper className="form-group">
          {children ||
            FORM.map((row, i) => {
              if (row.isHidden && row.isHidden(formik.values)) return null;
              return (
                <Row key={i} className="row">
                  {row.items.map((col, j) => {
                    if (col.isHidden && col.isHidden(formik.values)) return null;
                    return (
                      <Col key={j} gridSize={col.gridSize || 6} padding={SPACE.SMALL}>
                        {input.generateInput(col, formik, inputType, callback)}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
        </Wrapper>
      </Space.Bottom>
    </>
  );
};

export default FormSection;
