import { desktopStyle } from '$gstyles/utils';
import { Dialog } from '@material-ui/core';
import styled from 'styled-components';

export const DialogModal = styled(Dialog)`
  .close-button {
    position: absolute;
    right: 0;
  }

  ${p =>
    desktopStyle(`
    .MuiDialogContent-root {
      min-width: ${p.width};
      position: static;
    }
  `)}
`;
