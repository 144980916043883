import { INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

const FILTERS: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        placeholder: 'John',
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        placeholder: 'Doe',
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
      },
    ],
  },
];

export default FILTERS;
