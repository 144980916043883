import React from 'react';
import { connect } from 'react-redux';
import { IonPage } from '@ionic/react';

import intl from '$gintl';
import UserModel from '$gbusiness/models/user';
import { userActions } from '$gbusiness/redux/user';
import { screen } from '$gcomponents/hoc';
import { Header } from '$gcomponents/widgets';

import FormView from './formView';

interface UserScreenProps {
  onHydrate: Function;
  onDehydrate: Function;
  saveUser: Function;
  match: any;
  user: UserModel;
}

const UserScreen: React.FC<UserScreenProps> = React.memo(
  ({ user, saveUser, match }) => {
    const {
      params: { userId },
    } = match;
    const isNewUser = isNaN(userId);
    const title = isNewUser
      ? intl('SCREEN.USER.TITLE_NEW')
      : intl('SCREEN.USER.TITLE', { firstName: user?.firstName, lastName: user?.lastName });

    return (
      <IonPage>
        <Header titleText={title} />
        <FormView user={user} onSubmit={saveUser} />
      </IonPage>
    );
  },
  (pp, np) => pp.user === np.user,
);

const mapStateToProps = state => ({
  user: state.user.user,
});

const mapDispatchToProps = {
  onHydrate: params => userActions.fetchUser(params.userId),
  saveUser: (userId, params) => userActions.saveUser(userId, params),
  onDehydrate: () => userActions.dehydrate(),
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(UserScreen));
