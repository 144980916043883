import React, { useState } from 'react';

import { connect } from 'react-redux';
import { IonPage, IonButton, IonPopover, IonIcon } from '@ionic/react';
import { ellipsisVerticalSharp } from 'ionicons/icons';

import { screen } from '$gcomponents/hoc';
import { Header, TableView } from '$gcomponents/widgets';

import TABLE, { AUTO_COMPLETE, TABLE_CONFIG } from './table';
import FILTERS from './filters';
import PopMenu from './popMenu';

const UsersScreen: React.FC = () => {
  const [selections, setSelections] = useState([]);
  const [showActions, setShowActions] = useState<{ open: boolean; event: Event | undefined }>({
    open: false,
    event: undefined,
  });

  const rightButton = (
    <IonButton onClick={e => setShowActions({ open: true, event: e.nativeEvent })}>
      <IonIcon slot="icon-only" md={ellipsisVerticalSharp} />
    </IonButton>
  );

  return (
    <IonPage>
      <Header title="SCREEN.USERS.TITLE" rightButton={rightButton} />
      <IonPopover
        isOpen={showActions.open}
        event={showActions.event}
        onDidDismiss={e => {
          setShowActions({ open: false, event: undefined });
        }}>
        <PopMenu
          onClick={() => {
            setShowActions({ open: false, event: undefined });
          }}
        />
      </IonPopover>
      <TableView
        AC={AUTO_COMPLETE}
        TABLE={TABLE}
        config={TABLE_CONFIG}
        FILTERS={FILTERS}
        onSelection={rows => setSelections(rows)}
      />
    </IonPage>
  );
};

const mapStateToProps = () => ({ resetOnRoute: false });

const connected = connect(mapStateToProps, null);

export default connected(screen(UsersScreen));
