import intl from '$gintl';
import { ASC, DSC } from '../enums/sort';

export const sleep = m => new Promise(r => setTimeout(r, m));

export const changeSort = order => (order === DSC ? ASC : DSC);

export const flattenObject = obj => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map(k => (typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] })),
      );
    })(obj),
  );
};

export const mirrorObjKeys = obj => {
  const newObj = {};
  for (let i in obj) {
    newObj[i] = i;
  }
  return newObj;
};

export const getStyleColor = (color, colorType = '') => {
  return `var(--ion-color-${color}${colorType ? `-${colorType}` : ''})`;
};

export const addMinutesToTimestamp = (seconds, timestamp = 0) => {
  const now = timestamp || +new Date();

  return now + seconds * 1000;
};

export const currency = amount => intl('PRICE', { amount });
