import { format as dateFormat, parseISO } from 'date-fns';

export const today = () => {
  return dateFormat(new Date(), 'yyyy-MM-dd');
};

export const format = (dateStr, formatStr = 'yyyy-MM-dd') => {
  return dateFormat(parseISO(dateStr), formatStr);
};

export const formatTime = (dateStr, formatStr = 'hh:mm a') => {
  return dateFormat(parseISO(dateStr), formatStr);
};

export const formatDatetime = (dateStr, formatStr = 'yyyy-MM-dd hh:mm a') => {
  return dateFormat(parseISO(dateStr), formatStr);
};
