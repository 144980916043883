import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { IonContent, IonFooter } from '@ionic/react';
import { useHistory } from 'react-router-dom';

import { AppModel } from '$business/redux';
import { checkCustomerCode, createPaymentRequest, dehydrate } from '$business/redux/invoice/actions';
import intl from '$intl';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';
import { screen } from '$gcomponents/hoc';

import { CUSTOMER_FORM, TRANSACTION_FORM } from './SendLinkForm';
import { Div, H2, SPACE, Space } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { IonPageWrapper } from './styles';
import { defaultPaymentRequest } from '$business/models/paymentRequest';

const initialFormValues = defaultPaymentRequest;

const SendLinkScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const invoice = useSelector((state: AppModel) => state.invoice);
  const formRef = useRef<any>();

  const derivedInitialValues = {
    ...input.deriveFormInitialValues(CUSTOMER_FORM, initialFormValues),
    ...input.deriveFormInitialValues(TRANSACTION_FORM, initialFormValues),
  };

  const validateForm = values => input.validateError([...CUSTOMER_FORM, ...TRANSACTION_FORM], values);
  const onSubmit = values => {
    dispatch(createPaymentRequest(values));
  };
  const closeScreen = history.goBack;

  useEffect(() => {
    if (!invoice.isFinished) return;
    dispatch(dehydrate());
    if (formRef?.current && formRef.current.resetForm) formRef.current.resetForm();
    closeScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.isFinished]);

  return (
    <IonPageWrapper>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={derivedInitialValues}
        validate={validateForm}
        onSubmit={onSubmit}>
        {formik => {
          return (
            <>
              <IonContent>
                <Div padding={SPACE.LARGE} maxWidth="800px">
                  <H2 textAlign="left">{intl('SCREEN.PAYMENT_REQUEST.TITLE')}</H2>
                  <Form>
                    <Space.Ver padding={SPACE.SMALL} />
                    <FormSection
                      title="SCREEN.PAYMENT_REQUEST.SUBTITLE"
                      FORM={CUSTOMER_FORM}
                      formik={formik}
                      callback={value => {
                        dispatch(checkCustomerCode(value));
                      }}
                    />
                    <FormSection
                      title="SCREEN.PAYMENT_REQUEST.SUBTITLE2"
                      FORM={TRANSACTION_FORM}
                      formik={formik}
                    />
                  </Form>
                  {invoice.isValidCustomerCode && <div>{intl('MESSAGE.VALID_CUSTOMER_CODE')}</div>}
                  {invoice.isValidCustomerCode === false && (
                    <div>{intl('MESSAGE.INVALID_CUSTOMER_CODE')}</div>
                  )}
                </Div>
              </IonContent>
              <IonFooter>
                <Flex padding={SPACE.MEDIUM} justifyContent="space-between">
                  <Button variant="outlined" onClick={closeScreen}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!(formik.isValid && invoice.isValidCustomerCode)}
                    color="primary"
                    className="submit-button"
                    isLoading={invoice.isProcessing}
                    loadingText={intl('SCREEN.PAYMENT_REQUEST.SUBMIT_BUTTON_LOADING')}
                    variant="contained"
                    onClick={formik.handleSubmit}>
                    {intl('SCREEN.PAYMENT_REQUEST.SUBMIT_BUTTON')}
                  </Button>
                </Flex>
              </IonFooter>
            </>
          );
        }}
      </Formik>
    </IonPageWrapper>
  );
};

const connected = connect(null, null);

export default connected(screen(SendLinkScreen));
