import styled from 'styled-components';
import { Div } from '$gstyles';

export const LineWrapper = styled(Div)`
  display: flex;
  align-items: center;

  > *:not(:first-child):not(:last-child) {
    margin-left: ${p => p.spacing};
    margin-right: ${p => p.spacing};
  }

  > *:first-child {
    margin-right: ${p => p.spacing};
  }
  > *:last-child {
    margin-left: ${p => p.spacing};
  }
`;
