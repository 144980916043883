import { LoginScreen, ForgotPasswordScreen, RegisterScreen, ResetPasswordScreen } from '$gbox/screens';

export const LOGIN = '/login';
export const FORGOT = '/forgot';
export const REGISTER = '/register';
export const RESET_PASSWORD = '/resetpassword';
export const USERS = '/users';
export const EMPTY = '';

export const PUBLIC_LIST = [
  {
    path: LOGIN,
    component: LoginScreen,
    exact: true,
  },
  {
    path: FORGOT,
    param: '',
    component: ForgotPasswordScreen,
    exact: true,
  },
  {
    path: RESET_PASSWORD,
    param: ':token/:email',
    component: ResetPasswordScreen,
    exact: true,
  },
  {
    path: REGISTER,
    param: '',
    component: RegisterScreen,
    exact: true,
  },
];

export const DEFAULT_PUBLIC = PUBLIC_LIST[0];
export const DEFAULT_PUBLIC_ROUTE = PUBLIC_LIST[0].path;
