import _ from 'lodash';
import React from 'react';
import { FormControl, Grid, TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { configs } from '$configs';
import { FastField } from 'formik';
import intl from '$gintl';

import { BSLabel } from './styles';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

interface PlaceType {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

interface GoogleAddressProps {
  label?: string;
  name: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  fullWidth?: boolean;
  formik?: any;
  size?: string;
  shrink?: boolean;
  handleChange?: (e) => void;
  handleBlur?: Function;
  requestOptions?: any;
}

const GoogleAddress: React.FC<GoogleAddressProps> = ({
  label = '',
  name = '',
  readonly = false,
  disabled = false,
  placeholder = '',
  position = undefined,
  fullWidth = false,
  formik = {},
  size = configs.display.inputSize,
  shrink = undefined,
  handleChange = e => {},
  handleBlur = () => {},
  requestOptions = {
    componentRestrictions: { country: 'us' },
    types: ['geocode'],
  },
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 500),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google && (window as any).google.maps.places) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        ...requestOptions,
        input: inputValue,
      },
      (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;
  const inputSize = size === 'small' ? 'small' : 'medium';

  const labelProps = position
    ? {
        label: intl(label),
      }
    : {};
  const variant = position || 'outlined';

  const onChange = e => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const onBlur = e => {
    if (formik.handleBlur) formik.handleBlur(e);
    if (handleBlur) handleBlur(e);
  };

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      value={value}
      popupIcon={null}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        formik.setFieldValue(name, newValue?.description);
        formik.setFieldValue('googlePlaceId', newValue?.place_id);
        formik.setFieldValue('googleAddress1', newValue?.structured_formatting.main_text);
        formik.setFieldValue('googleAddress2', newValue?.structured_formatting.secondary_text);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={option => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {option.structured_formatting.main_text}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      renderInput={params => {
        const more = {
          ...params,
          InputLabelProps: {
            ...(shrink && { shrink }),
          },
          // InputLabelProps: { shrink },
        };
        return (
          <FastField name={name}>
            {({ form }) => (
              <FormControl size={inputSize} fullWidth>
                {!position && (
                  <BSLabel className="bs-label" shrink={true}>
                    {intl(label)}
                  </BSLabel>
                )}
                <TextField
                  {...labelProps}
                  type="text"
                  name={name}
                  onInput={onChange}
                  onBlur={onBlur}
                  placeholder={intl(placeholder) || undefined}
                  size={inputSize}
                  variant={variant}
                  error={hasError}
                  helperText={errorMsg}
                  value={_.get(form.values, name) || ''}
                  {...more}
                />
                <input type="hidden" name="googlePlaceId" />
                <input type="hidden" name="googleAddress1" />
                <input type="hidden" name="googleAddress2" />
              </FormControl>
            )}
          </FastField>
        );
      }}
    />
  );
};

export default GoogleAddress;
