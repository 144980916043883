import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';

import { IconInputWrapper } from '$styles/input';
import { Inline, SPACE, Space } from '$gstyles';

interface LeftComponentsProps {}

const LeftComponents: React.FC<LeftComponentsProps> = () => {
  return (
    <>
      <IconInputWrapper>
        <TextField
          label=""
          variant="outlined"
          placeholder="Search List"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
      <Space.Hor margin={SPACE.MEDIUM} />
      <DatePicker
        label=""
        size="small"
        inputVariant="outlined"
        value={new Date()}
        onChange={() => {}}
        format="M/d/yyyy"
        style={{ width: '100px' }}
      />
      <Inline className="flexCenter">To</Inline>

      <IconInputWrapper>
        <DatePicker
          label=""
          size="small"
          inputVariant="outlined"
          value={new Date()}
          onChange={() => {}}
          format="M/d/yyyy"
          style={{ width: '140px' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </IconInputWrapper>
    </>
  );
};

export default LeftComponents;
