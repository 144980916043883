import React, { useState } from 'react';
import {
  Checkbox,
  TableSortLabel,
  Table as MTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { Text } from '$gintl';
import { SORT } from '$gbusiness/enums';
import { util } from '$ghelpers';
import { generateSelects } from './utils';
import { Vwrapper } from './styles';
import { ENUMS, SPACE, Space } from '$gstyles';
import { TableDisplayModel, TableStylesModel, defaultDisplay, defaultStyles } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';

interface TableProps {
  display: TableDisplayModel;
  styles: TableStylesModel;
  TABLE: Array<CellModel>;
  data: Array<any>;
  onChangePage?: Function;
  onChangeSort?: Function;
  onSelection?: Function;
  itemActions?: { Function };
}

const Table: React.FC<TableProps> = React.memo(
  ({
    display = defaultDisplay,
    styles = defaultStyles,
    TABLE,
    data,
    onChangePage = () => {},
    onSelection,
    onChangeSort = () => {},
    itemActions,
  }) => {
    const {
      page,
      pageSize: rowsPerPage = defaultDisplay.pageSize,
      totalDataSize = defaultDisplay.totalDataSize,
      sortKey,
      sortOrder,
      isSelectable,
    } = display;
    const pageSize = rowsPerPage === 0 ? totalDataSize : rowsPerPage || totalDataSize;
    const {
      color,
      isStripped,
      cellPadding,
      checkWidth,
      minWidth,
      maxWidth,
      headerHeight = 40,
      rowHeight = 40,
    } = styles;
    const [checkedRows, setCheckedRows] = useState<Array<boolean>>(generateSelects(pageSize));
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const isMobile = useMediaQuery(`(max-width:${ENUMS.MOBILE_WIDTH})`);
    const numberOfPages = Math.floor((totalDataSize - 1) / pageSize) + 1;

    const checkRow = i => {
      const rows = [...checkedRows];
      rows[i] = !rows[i];
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
    };

    const checkAllRows = () => {
      const newSelectAll = !selectAll;
      const rows = generateSelects(pageSize, newSelectAll);
      setCheckedRows(rows);
      if (onSelection) onSelection(rows);
      setSelectAll(newSelectAll);
    };

    if (!data.length) {
      return <div></div>;
    }

    return (
      <Vwrapper
        minWidth={minWidth}
        maxWidth={maxWidth}
        headerColor={color}
        stripped={isStripped}
        cellPadding={cellPadding}
        rowHeight={rowHeight}
        headerHeight={headerHeight!}>
        <MTable component="div" size="small" className="virtualized-table">
          {TABLE && (
            <TableHead component="div">
              <TableRow component="div" className="virtualized-row">
                {isSelectable && (
                  <TableCell component="div" className="center" style={{ flex: `0 1 ${checkWidth || 60}px` }}>
                    <span className="selectCol">
                      <Checkbox checked={selectAll} className="select-all" onClick={checkAllRows} />
                    </span>
                  </TableCell>
                )}
                {TABLE.filter(cell => !cell.isHidden).map((cell, i) => (
                  <TableCell
                    component="div"
                    key={i}
                    className={cell.align || ''}
                    style={{ flex: `0 1 ${cell.width}px` }}>
                    {cell.sortable && (
                      <TableSortLabel
                        className={cell.align || ''}
                        active={sortKey === cell.value}
                        direction={sortOrder === SORT.ASC ? 'asc' : 'desc'}
                        onClick={() => {
                          onChangeSort(cell.value, sortKey !== cell.value ? '' : util.changeSort(sortOrder));
                        }}>
                        <Text k={cell.label} />
                      </TableSortLabel>
                    )}
                    {!cell.sortable && <Text k={cell.label} />}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody component="div">
            {data.map((row, i) => (
              <TableRow component="div" key={i} className="virtualized-row">
                {isSelectable && (
                  <TableCell component="div" className="center" style={{ flex: `0 1 ${checkWidth || 60}px` }}>
                    <Checkbox checked={checkedRows[i]} onClick={() => checkRow(i)} />
                  </TableCell>
                )}
                {TABLE.filter(cell => !cell.isHidden).map((cell, i) => (
                  <TableCell
                    component="div"
                    key={i}
                    className={cell.align || ''}
                    style={{ flex: `0 1 ${cell.width}px` }}>
                    {cell.component && cell.component(row, itemActions)}
                    {!cell.component && row[cell['value']]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MTable>

        {numberOfPages > 1 && (
          <Space.Ver className="center" padding={SPACE.MEDIUM}>
            <Pagination
              count={numberOfPages}
              siblingCount={isMobile ? 1 : 2}
              defaultPage={page}
              boundaryCount={1}
              showFirstButton={!isMobile}
              showLastButton={!isMobile}
              onChange={(e, pageNumber) => {
                onChangePage(pageNumber);
              }}
            />
          </Space.Ver>
        )}
      </Vwrapper>
    );
  },
);

export default Table;
