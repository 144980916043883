import styled from 'styled-components';
import { ENUMS, SPACE } from '$gstyles';
import { ListContainer } from '$gstyles/wrapper';

export const Wrapper = styled(ListContainer)`
  .MuiButton-root {
    text-transform: initial;
  }
  .filters {
    justify-content: space-between;
    display: flex;
    margin-top: ${SPACE.LARGE};
  }
  @media screen and (max-width: ${ENUMS.SPLIT_PANE_WIDTH}) {
    .filters {
      display: block;
    }
  }
`;
