import {
  InvoiceActionTypes,
  InvoiceReducerType,
  INVOICE_INIT_STATE,
  RESET_FAILURE,
  RESET_SUCCESS,
  RESET_CUSTOMER_CODE,
  SEND_INVOICES_SUCCESS,
  CHECK_CUSTOMER_CODE_SUCCESS,
  RESET_ALL,
  IS_PROCESSING,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE,
  VALIDATE_INVOICE_SUCCESS,
  PAYMENT_SUCCESS,
} from './types';

export default function(
  state: InvoiceReducerType = INVOICE_INIT_STATE,
  action: InvoiceActionTypes,
): InvoiceReducerType {
  switch (action.type) {
    case IS_PROCESSING:
      return {
        ...state,
        isProcessing: true,
      };
    case SEND_INVOICES_SUCCESS:
      return {
        ...state,
        numSuccess: action.numSuccess,
        numFailure: action.numFailure,
        isProcessing: false,
        isFinished: true,
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        isFinished: true,
      };
    case CREATE_INVOICE_FAILURE:
      return {
        ...state,
        isProcessing: false,
      };
    case RESET_FAILURE:
      return {
        ...state,
        numFailure: INVOICE_INIT_STATE.numFailure,
        isProcessing: false,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        numSuccess: INVOICE_INIT_STATE.numSuccess,
      };
    case CHECK_CUSTOMER_CODE_SUCCESS:
      return {
        ...state,
        isValidCustomerCode: action.isValid,
        isProcessing: false,
      };
    case RESET_CUSTOMER_CODE:
      return {
        ...state,
        isValidCustomerCode: INVOICE_INIT_STATE.isValidCustomerCode,
      };
    case VALIDATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.invoice,
      };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case RESET_ALL:
      return INVOICE_INIT_STATE;
    default:
      return state;
  }
}
