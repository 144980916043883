import UserModel from '$gbusiness/models/user';

export const SET_CUSER = 'SET_CUSER';
export const CLEAN_CUSER = 'CLEAN_CUSER';

// Action Types
export interface SetCuserType {
  type: typeof SET_CUSER;
  user: UserModel;
}

export interface CleanCuserType {
  type: typeof CLEAN_CUSER;
}

export type CuserActionTypes = SetCuserType | CleanCuserType;

// Reducer Model
export const CUSER_INIT_STATE = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
};
