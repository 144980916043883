import { configs } from '$configs';

import HeadersModel from '$business/models/headers';

export const INIT_SUCCESS = 'INIT_SUCCESS';
export const LOAD_HEADERS = 'LOAD_HEADERS';

// Action Types
export interface InitSuccessType {
  type: typeof INIT_SUCCESS;
}
export interface LoadHeadersType {
  type: typeof LOAD_HEADERS;
  headers: HeadersModel;
}

export interface InitModel {
  headers: HeadersModel;
  initSuccess: boolean;
  public?: {
    resetRequested?: boolean;
    err?: string | undefined;
  };
}

export const headersInitState: HeadersModel = {
  env: (process.env.REACT_APP_ENV || '').toString(),
  appName: configs.appName,
};

export const INIT_STATE: InitModel = {
  headers: headersInitState,
  initSuccess: false,
  public: undefined,
};
