import React from 'react';

import { Div, SPACE } from '$gstyles';
// import { useMediaQuery } from '@material-ui/core';
import { LineWrapper } from '$styles/wrapper';
import { Wrapper } from './styles';

interface ListFilterWrapperProps {
  isHidden?: boolean;
  children: React.ReactNode;
  rightComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  tabComponent?: React.ReactNode;
  shouldVirtualize?: boolean;
  additionalHeight?: number;
}

const ListFilterWrapper: React.FC<ListFilterWrapperProps> = ({
  additionalHeight = 0,
  isHidden,
  children,
  leftComponent,
  tabComponent,
  rightComponent,
  shouldVirtualize,
}) => {
  // const isMobile = useMediaQuery(`(max-width: ${ENUMS.SPLIT_PANE_WIDTH})`);
  // const HEADER_HEIGHT = isMobile ? 150 : 100;
  const HEADER_HEIGHT = 100;

  return (
    <Wrapper
      className={shouldVirtualize ? 'virtualized' : 'not-virtualized'}
      height={isHidden ? '0px' : undefined}>
      <Div padding={SPACE.LARGE} overflow="auto" paddingBottom={0} minHeight={`${HEADER_HEIGHT}px`}>
        {tabComponent}
        <Div className="filters">
          <LineWrapper className="container left" spacing={SPACE.SMALL} minWidth="600px">
            {leftComponent}
          </LineWrapper>
          <LineWrapper className="container right" spacing={SPACE.SMALL}>
            {rightComponent}
          </LineWrapper>
        </Div>
      </Div>
      <Div
        flex={1}
        overflow="auto"
        height={`calc(100% - ${additionalHeight + HEADER_HEIGHT}px)`}
        margin="16px">
        {children}
      </Div>
    </Wrapper>
  );
};

export default ListFilterWrapper;
