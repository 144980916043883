import _ from 'lodash';
import React from 'react';
import { Select, FormControlProps, FormHelperText, OutlinedInput } from '@material-ui/core';
import { FastField } from 'formik';

import { configs } from '$configs';
import intl from '$gintl';
import { input } from '$gcomponents/utils';

import { BSLabel, FormControlWrapper } from './styles';

interface SelectInputProps {
  icon?: string;
  label?: string;
  name: string;
  formik?: any;
  size?: string;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  shrink?: boolean;
  position?: FormControlProps['variant'];
  multiSelect?: boolean;
  options: React.ReactNode | null;
  defaultValue?: string | number | null;
  handleChange?: (e) => void;
}

const defaultProps: SelectInputProps = {
  icon: '',
  label: '',
  name: '',
  formik: {},
  size: configs.display.inputSize,
  disabled: false,
  readonly: false,
  placeholder: '',
  shrink: undefined,
  position: undefined,
  multiSelect: false,
  options: null,
  defaultValue: null,
  handleChange: () => {},
};

const SelectInput: React.FC<SelectInputProps> = ({
  icon,
  label,
  name,
  formik,
  size,
  disabled,
  readonly,
  placeholder,
  shrink,
  position,
  multiSelect,
  options,
  defaultValue,
  handleChange,
  ...props
}) => {
  const onChange = e => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const menus = input.generateSelectOptions(options, true);

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const inputSize = size === 'small' ? 'small' : 'medium';

  const labelProps =
    position || shrink
      ? {
          label: intl(label),
          ...(shrink && { input: <OutlinedInput name={name} label={intl(label)} notched /> }),
        }
      : {};
  const variant = position || 'outlined';

  return (
    <>
      <FastField name={name}>
        {({ form }) => (
          <FormControlWrapper size={inputSize} error={hasError} variant={variant} fullWidth>
            <BSLabel id={`label_${name}`} className={!position && 'bs-label'} shrink={shrink}>
              {intl(label)}
            </BSLabel>
            <Select
              {...props}
              {...labelProps}
              labelId={`label_${name}`}
              multiple={multiSelect}
              name={name}
              disabled={disabled}
              placeholder={intl(placeholder) || undefined}
              onChange={onChange}
              displayEmpty
              value={_.get(form.values, name) || ''}>
              {menus}
            </Select>
            <FormHelperText>{errorMsg}</FormHelperText>
          </FormControlWrapper>
        )}
      </FastField>
    </>
  );
};

SelectInput.defaultProps = defaultProps;

export default SelectInput;
