import styled from 'styled-components';
import Div from './div';

export const Relative = styled(Div)`
  position: relative;
`;

export const Valign = styled(Div)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const Inline = styled(Div)`
  position: relative;
  display: inline-block;
  ${props => (props.top ? `top: ${props.top};` : '')}
  ${props => (props.bottom ? `top: ${props.bottom};` : '')}
  ${props => (props.left ? `top: ${props.left};` : '')}
  ${props => (props.right ? `top: ${props.right};` : '')}
`;

export const InputError = styled(Div)`
  flex: initial;
  float: right;
  position: absolute;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 0.8em;
  right: 10px;
  color: var(--ion-color-danger);
`;

export const Center = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
