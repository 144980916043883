export default interface UserModel {
  userId?: number;
  firstName: string;
  lastName: string;
  email: string;
}

export const emptyUser: UserModel = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
};

export interface LoginModel {
  email: string;
  password: string;
  remember: boolean;
}

export interface RegisterModel {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}
