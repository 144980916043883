import React from 'react';
import intl from '$intl';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import InvoiceModel from '$business/models/invoice';
import { Div, Space, SPACE } from '$gstyles';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$ghelpers';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { submitCardPayment } from '$business/redux/invoice/actions';

import { PAYMENT_FORM } from '../paymentForm';
import PaymentModel, { defaultPayment } from '$business/models/payment';

interface PaymentSectionProps {
  invoice: InvoiceModel;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ invoice }) => {
  const dispatch = useDispatch();
  //const initialValues:PaymentModel = defaultPayment;
  const initialValues: PaymentModel = {
    ...defaultPayment,
    invoiceId: invoice.invoiceId,
    email: invoice.email,
    cardNumber: '4444555566667777',
    cardholder: 'Peter Yoon',
    expiry: '03/22',
    cvc: '333',
    zip: '19422',
    country: 'UNITED_STATES',
    amount: 327,
    saveCard: true,
  };
  const total = (invoice.tax || 0) + invoice.amount;
  const totalCurrency = intl('PRICE', { amount: total });

  // Methods
  const t = key => intl(`SCREEN.PAYMENT.${key}`);
  const validateForm = values => input.validateError(PAYMENT_FORM, values);
  const onSubmit = values => {
    dispatch(submitCardPayment(values));
  };

  const PriceItem = ({ label, amount }) => {
    return (
      <Flex justifyContent="space-between" paddingTop={SPACE.LARGE}>
        <Div>{t(label)}</Div>
        <Div>{intl('PRICE', { amount })}</Div>
      </Flex>
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <div className="form-container">
            <Space.All padding={SPACE.LARGE}>
              <h4>{t('TITLE')}</h4>
              <Div margin={`${SPACE.LARGE} 0`} className="subtitle">
                {t('SUBTITLE')}
              </Div>
              <FormSection formik={formik} FORM={PAYMENT_FORM} />
              <PriceItem label="SUBTOTAL" amount={invoice.amount} />
              <PriceItem label="VAT" amount={invoice.tax} />
              <PriceItem label="TOTAL" amount={total} />
              <Div margin={`${SPACE.LARGE} 0`}>
                <Space.Top padding={SPACE.LARGE} />
                <Button fullWidth onClick={formik.handleSubmit} disabled={!formik.isValid}>
                  {intl('SCREEN.PAYMENT.SUBMIT_BUTTON', { amount: totalCurrency })}
                </Button>
              </Div>
            </Space.All>
          </div>
        );
      }}
    </Formik>
  );
};

export default PaymentSection;
