import { Dispatch } from 'redux';

import { LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { localStorage, theme } from '$gbusiness/services';
import { ROUTES } from '$business/enums';

import { AppModel } from '../';
import { INIT_SUCCESS } from './types';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await localStorage.getStorageData();

  dispatch({
    type: LOAD_STORAGE,
    localStorage: storageData,
  });
}

const shouldForcePublic = () => {
  const path = window.location.pathname;

  const thisRoute = ROUTES.PUBLIC_LIST.find(route => path.includes(route.path));
  return thisRoute ? true : false;
};

export function loadApp() {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    if (!shouldForcePublic()) {
      // Load LOCAL STORAGE
      await loadStorage(dispatch);
    }

    // Load Theme
    const { localStorage } = getState();

    restoreTheme(localStorage.theme);

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}
