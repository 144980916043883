import _ from 'lodash';
import React from 'react';
import { format as dateFormat } from 'date-fns';
import { IonItem, IonLabel, IonIcon, IonDatetime } from '@ionic/react';
import { FastField, ErrorMessage } from 'formik';

import intl from '$gintl';
import { Styles } from '$gstyles';
import { configs } from '$configs';

interface DateInputProps {
  icon?: string;
  label?: string;
  name: string;
  formik?: any;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  position?: string;
  minDate?: string;
  maxDate?: string;
  format?: string;
  defaultValue?: string;
  handleChange?: (e) => void;
}

const defaultProps: DateInputProps = {
  icon: '',
  label: '',
  name: '',
  formik: {},
  readonly: false,
  disabled: false,
  placeholder: '',
  position: '',
  minDate: '2010',
  maxDate: dateFormat(new Date(), 'yyyy-MM-dd'),
  format: 'MM/dd/yyyy',
  defaultValue: '',
  handleChange: e => {},
};

const getPosition = position => {
  return position || configs.defaultLabelPosition;
};

const DateInput: React.FC<DateInputProps> = ({
  icon,
  label,
  name,
  formik,
  readonly,
  disabled,
  placeholder,
  position,
  minDate,
  maxDate,
  format,
  defaultValue,
  handleChange,
  ...props
}) => {
  const onChange = e => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  return (
    <>
      <FastField name={name}>
        {({ form }) => (
          <>
            <IonItem>
              {icon !== '' && <IonIcon slot="start" icon={icon} />}
              {label !== '' && <IonLabel position={getPosition(position)}>{intl(label)}</IonLabel>}
              <ErrorMessage name={name}>
                {e => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
              </ErrorMessage>
              <IonDatetime
                {...props}
                readonly={readonly}
                disabled={disabled}
                name={name}
                min={minDate}
                max={maxDate}
                displayFormat={format}
                placeholder={intl(placeholder) || undefined}
                onIonChange={onChange}
                value={_.get(form.values, name)}></IonDatetime>
            </IonItem>
          </>
        )}
      </FastField>
    </>
  );
};

DateInput.defaultProps = defaultProps;

export default DateInput;
