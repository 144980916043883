import React from 'react';
import { IonHeader, IonBackButton, IonButtons, IonMenuButton, IonTitle } from '@ionic/react';

import { configs } from '$configs';
import intl from '$gintl';

import { Toolbar } from './styles';

interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  rightButton?: React.ReactNode;
  excludeHeader?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title,
  titleText,
  titleParam = {},
  rightButton,
  excludeHeader = false,
}) => {
  if (configs.display.layout === 'FIXED_HEADER') return null;

  const derivedTitle = intl(title, titleParam) || titleText || '';
  const el = (
    <Toolbar color={configs.theme.headerColor || undefined}>
      <IonButtons slot="start">
        <IonBackButton />
      </IonButtons>
      <IonButtons slot="start">
        <IonMenuButton />
      </IonButtons>
      <IonTitle>{derivedTitle}</IonTitle>
      <IonButtons slot="end">{rightButton && rightButton}</IonButtons>
    </Toolbar>
  );

  return excludeHeader ? <>{el}</> : <IonHeader>{el}</IonHeader>;
};

export default Header;
