import { INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

export const USER_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
      },
      {
        ...INPUT_TYPES.LAST_NAME,
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 6,
      },
    ],
  },
];

export default USER_FORM;
