import React from 'react';
import { IonSelectOption } from '@ionic/react';
import MenuItem from '@material-ui/core/MenuItem';

import { INPUT } from '../../business/enums';
import {
  MTextInput,
  MSelectInput,
  MDateInput,
  TextInput as ITextInput,
  SelectInput as ISelectInput,
  DateInput as IDateInput,
  MCheckbox as Checkbox,
  Text,
  GoogleAddress,
} from '$gcomponents/primitives';
import { configs } from '$configs';

export const generateSelectOptions = (list, isMaterial = false) =>
  isMaterial
    ? list.map((o, i) => (
        <MenuItem value={o.value} key={i}>
          <Text k={`INPUT.OPTION.${o.label}`} />
        </MenuItem>
      ))
    : list.map((o, i) => (
        <IonSelectOption value={o.value} key={i}>
          <Text k={`INPUT.OPTION.${o.label}`} />
        </IonSelectOption>
      ));

// Generates and returns correct JSX component given a form field meta data
export const generateInput = (
  filter,
  formik,
  inputType = configs.display.inputType === 'undefined' ? undefined : configs.display.inputType,
  callback: Function = () => {},
) => {
  const { values, setFieldValue } = formik;
  const onChangeInput = (e, newValue = null) => {
    if (!filter.onChange) return;
    const value = newValue !== null ? newValue : e.target.value;
    filter.onChange({
      value,
      formValues: values,
      setFieldValue,
      callback,
    });
  };
  const onBlur = (e, newValue = null) => {
    if (!filter.onBlur) return;
    const value = newValue !== null ? newValue : e.target.value;
    filter.onBlur({
      value,
      callback,
    });
  };

  const TextInput = filter.lib === 'ionic' ? ITextInput : MTextInput;
  const DateInput = filter.lib === 'ionic' ? IDateInput : MDateInput;
  const SelectInput = filter.lib === 'ionic' ? ISelectInput : MSelectInput;

  const commonProps = {
    formik,
    label: filter.label,
    name: filter.value,
    size: filter.size,
    defaultValue: filter.defaultValue || undefined,
    handleChange: onChangeInput,
    readonly: filter.readonly,
    disabled: filter.disabled,
    placeholder: filter.placeholder || undefined,
  };

  const textProps = {
    icon: filter.icon,
    position: filter.position || inputType,
    maskType: filter.maskType,
    prefix: filter.prefix,
    postfix: filter.postfix,
    enterToSubmit: filter.enterToSubmit,
    handleBlur: onBlur,
    shrink: filter.shrink,
  };

  switch (filter.input) {
    case INPUT.TEXT:
      return (
        <TextInput type={INPUT.TYPES.TEXT} maxLength={filter.maxLength} {...commonProps} {...textProps} />
      );
    case INPUT.PASSWORD:
      return (
        <TextInput type={INPUT.TYPES.PASSWORD} maxLength={filter.maxLength} {...commonProps} {...textProps} />
      );
    case INPUT.NUMBERTEXT:
      return (
        <TextInput
          type={INPUT.TYPES.TEXT}
          inputMode={INPUT.MODES.NUMERIC}
          maxLength={filter.maxLength}
          {...commonProps}
          {...textProps}
        />
      );
    case INPUT.NUMBER:
      return <TextInput type={INPUT.TYPES.NUMBER} {...commonProps} {...textProps} />;
    case INPUT.PHONE:
      return (
        <TextInput type={INPUT.TYPES.TEXT} inputMode={INPUT.MODES.NUMERIC} {...commonProps} {...textProps} />
      );
    case INPUT.CURRENCY:
      return (
        <TextInput
          type={INPUT.TYPES.NUMBER}
          inputMode={INPUT.MODES.NUMERIC}
          {...commonProps}
          {...textProps}
        />
      );
    case INPUT.EMAIL:
      return <TextInput type={INPUT.TYPES.EMAIL} {...commonProps} {...textProps} />;
    case INPUT.CHECKBOX:
      return <Checkbox slot={filter.slot} {...commonProps} />;
    case INPUT.SELECT:
      return (
        <SelectInput
          shrink={filter.shrink}
          icon={filter.icon}
          position={filter.position || inputType}
          options={filter.options}
          {...commonProps}
        />
      );
    case INPUT.DATE:
      return (
        <DateInput
          shrink={filter.shrink}
          icon={filter.icon}
          position={filter.position || inputType}
          format={filter.format}
          minDate={filter.minDate}
          maxDate={filter.maxDate}
          {...commonProps}
        />
      );
    case INPUT.ADDRESS:
      return (
        <GoogleAddress {...commonProps} shrink={filter.shrink} position={filter.position || inputType} />
      );
  }
};
