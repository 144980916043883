import UserModel from '$gbusiness/models/user';

export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const CLEAN_USER = 'CLEAN_USER';

export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';

// Action Types
export interface FetchUserSuccessType {
  type: typeof FETCH_USER_SUCCESS;
  user: UserModel | null;
}

export interface FetchUserFailureType {
  type: typeof FETCH_USER_FAILURE;
  err: string;
}

export interface SaveUserSuccessType {
  type: typeof SAVE_USER_SUCCESS;
  user: UserModel;
}

export interface SaveUserFailureType {
  type: typeof SAVE_USER_FAILURE;
  err: string;
}

export interface CleanUserType {
  type: typeof CLEAN_USER;
}

export interface UserReducerType {
  user: UserModel | null;
}

export type UserActionTypes =
  | FetchUserSuccessType
  | FetchUserFailureType
  | SaveUserSuccessType
  | SaveUserFailureType
  | CleanUserType;

export const USER_INIT_STATE: UserReducerType = {
  user: null,
};
