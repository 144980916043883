import UserModel from '$gbusiness/models/user';

import { SET_CUSER, CLEAN_CUSER, CUSER_INIT_STATE } from './types';
import { LOGIN_SUCCESS } from '../auth/types';

export default function(state: UserModel = CUSER_INIT_STATE, action): UserModel {
  switch (action.type) {
    case SET_CUSER:
      return action.user;
    case CLEAN_CUSER:
      return CUSER_INIT_STATE;
    case LOGIN_SUCCESS:
      return action.payload.user || CUSER_INIT_STATE;
    default:
      return state;
  }
}
