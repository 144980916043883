import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { validateInvoice, dehydrate } from '$business/redux/invoice/actions';
import InvoiceModel from '$business/models/invoice';
import { Col, Div, Row, SPACE } from '$gstyles';
import { Paper } from '@material-ui/core';

import { Wrapper } from './styles';
import PaymentSection from './components/paymentSection';
import InvoiceSection from './components/invoiceSection';
import ThankyouSection from './components/thankyouSection';
interface PaymentScreenProps {
  match: any;
  invoice: InvoiceModel;
  isFinished: boolean;
}

const PaymentScreen: React.FC<PaymentScreenProps> = ({ match, invoice, isFinished }) => {
  if (!invoice) return <IonPage></IonPage>;
  if (isFinished) {
    return (
      <IonPage>
        <IonContent>
          <Wrapper padding={SPACE.XLARGE}>
            <Paper className="thankyou-paper">
              <ThankyouSection />
            </Paper>
          </Wrapper>
        </IonContent>
      </IonPage>
    );
    // redirect to thank you screen
  }

  return (
    <IonPage>
      <IonContent>
        <Wrapper>
          <Row>
            <Col gridSize={6} breakpoint="960px">
              <Div padding={SPACE.LARGE}>
                <InvoiceSection invoice={invoice} />
              </Div>
            </Col>
            <Col gridSize={6} breakpoint="960px">
              <Div padding={SPACE.XLARGE}>
                <Paper className="form">
                  <PaymentSection invoice={invoice} />
                </Paper>
              </Div>
            </Col>
          </Row>
        </Wrapper>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => ({
  invoice: state.invoice.invoice,
  isFinished: state.invoice.isFinished,
});

const mapDispatchToProps = {
  onHydrate: uuid => validateInvoice(uuid),
  onDehydrate: dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(PaymentScreen));
