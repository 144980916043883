import { BILL_TYPE } from '$business/enums/options';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';
import { validateEmail } from '$ghelpers/input';

export const CUSTOMER_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        label: 'INPUT.LABEL.BILL.CUSTOMER_CODE',
        value: 'customerCode',
        input: INPUT.NUMBERTEXT,
        maxLength: 10,
        gridSize: 6,
        onBlur: async ({ value, callback }) => {
          callback(value);
        },
        validation: [
          input.requiredValidation,
          {
            condition: v => {
              return v.length < 4;
            },
            message: 'LENGTH_OVER',
          },
        ],
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'INPUT.LABEL.BILL.EMAIL',
        value: 'email',
        gridSize: 6,
        validation: [
          input.requiredValidation,
          {
            condition: v => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.BILL.ADDRESS',
        value: 'address',
        input: INPUT.TEXT,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.ZIP,
        label: 'INPUT.LABEL.BILL.ZIP',
        gridSize: 6,
      },
    ],
  },
];

export const TRANSACTION_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.AMOUNT,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.BILL.BILL_TYPE',
        value: 'invoiceType',
        input: INPUT.SELECT,
        options: BILL_TYPE,
        gridSize: 6,
        validation: [
          input.requiredValidation,
          {
            condition: v => v > 0,
            message: 'OVER_ZERO',
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.BILL.EMAIL_MESSAGE',
        value: 'message',
        input: INPUT.TEXT,
        gridSize: 12,
      },
    ],
  },
];
