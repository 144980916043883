import { INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$ghelpers';
import InputRowModel from '$gbusiness/models/inputRow';

const FORGOT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        placeholder: 'INPUT.PLACEHOLDER.EMAIL',
        validation: [
          input.requiredValidation,
          {
            condition: v => !input.validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
      },
    ],
  },
];

export default FORGOT_FORM;
