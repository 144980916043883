import React from 'react';
import { Tab, TabWrapper } from '$styles/tabs';

interface ListTabProps {
  height: number;
}

const ListTabs: React.FC<ListTabProps> = ({ height }) => {
  return (
    <TabWrapper height={height}>
      <Tab className="active">All</Tab>
      <Tab>General</Tab>
      <Tab>Scheduled</Tab>
    </TabWrapper>
  );
};

export default ListTabs;
