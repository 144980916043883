import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SPACE } from '$gstyles';

export const RouterLink = styled(Link)`
  text-decoration: none;
  ${p => (p.color ? `color: ${p.color};` : '')}
`;

export const ButtonWrapper = styled.div`
  padding: ${SPACE.LARGE};
`;
