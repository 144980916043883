import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$ghelpers';
import { validateEmail } from '$ghelpers/input';
import COUNTRIES from '$business/enums/options/country';

export const PAYMENT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        label: 'INPUT.LABEL.PAYMENT.EMAIL',
        gridSize: 12,
        validation: [
          input.requiredValidation,
          {
            condition: v => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.CARD_NUMBER,
        gridSize: 6,
        placeholder: '',
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.EXPIRY,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.CVC,
        gridSize: 3,
        value: 'cvc',
        validation: [input.requiredValidation],
      },
      {
        label: 'INPUT.LABEL.PAYMENT.NAME',
        value: 'cardholder',
        input: INPUT.TEXT,
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
  {
    items: [
      {
        label: 'INPUT.LABEL.PAYMENT.ADDRESS',
        value: 'country',
        input: INPUT.SELECT,
        options: COUNTRIES,
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.ZIP,
        gridSize: 5,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.STATE,
        gridSize: 7,
      },
      {
        label: 'INPUT.LABEL.PAYMENT.SAVE_CARD',
        value: 'saveCard',
        input: INPUT.CHECKBOX,
        gridSize: 12,
      },
    ],
  },
];
