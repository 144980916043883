import _ from 'lodash';
import React from 'react';
import { IonItem, IonLabel, IonIcon, IonCheckbox } from '@ionic/react';
import { FastField, ErrorMessage } from 'formik';

import intl from '$gintl';
import { Styles } from '$gstyles';

interface CheckboxProps {
  icon?: string;
  label?: string;
  name: string;
  disabled?: boolean;
  readonly?: boolean;
  position?: string;
  formik?: any;
  slot?: string;
  value?: any;
  handleChange?: (e) => void;
}

const defaultProps: CheckboxProps = {
  icon: '',
  label: '',
  name: '',
  disabled: false,
  readonly: false,
  position: 'floating',
  formik: {},
  slot: 'end',
  value: 'on',
  handleChange: () => {},
};

const TextInput: React.FC<CheckboxProps> = ({
  icon,
  label,
  name,
  disabled,
  readonly,
  position,
  formik,
  slot,
  value,
  handleChange,
  ...props
}) => {
  const onChange = e => {
    if (formik.setFieldValue) formik.setFieldValue(name, e.detail.checked);
    if (handleChange) handleChange(e);
  };

  return (
    <>
      <FastField name={name}>
        {({ form }) => {
          return (
            <>
              <IonItem className={readonly ? 'readonly' : ''}>
                {icon !== '' && <IonIcon slot="start" icon={icon} />}
                {label !== '' && <IonLabel>{intl(label)}</IonLabel>}
                <ErrorMessage name={name}>
                  {e => <Styles.InputError>{intl(`INPUT.ERROR.${e}`)}</Styles.InputError>}
                </ErrorMessage>
                <IonCheckbox
                  {...props}
                  name={name}
                  disabled={disabled}
                  slot={slot}
                  onIonChange={onChange}
                  checked={!!_.get(form.values, name)}
                  value={value}></IonCheckbox>
              </IonItem>
            </>
          );
        }}
      </FastField>
    </>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
