import React from 'react';
import { configs } from '$configs';
import { IonImg } from '@ionic/react';
import { Wrapper } from './styles';
import AppVersion from '../appVersion';

interface LogoHeaderProps {
  includeVersion?: boolean;
  size?: number;
  padding?: string;
}

const LogoHeader: React.FC<LogoHeaderProps> = ({ includeVersion = true, size = 250, padding = '0' }) => {
  return (
    <Wrapper width={size} padding={padding}>
      <IonImg className="logo" src={configs.display.logoPath} />
      {includeVersion && <AppVersion />}
    </Wrapper>
  );
};

export default LogoHeader;
