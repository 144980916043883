import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import {
  IonFooter,
  IonHeader,
  IonButtons,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
} from '@ionic/react';
import { Button, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import CellModel from '$gbusiness/models/cell';
import InputRowModel from '$gbusiness/models/inputRow';
import { Modal } from '$gcomponents/reusables';
import { table, input } from '$ghelpers';
import * as utils from '$gcomponents/utils';
import intl, { Text } from '$gintl';

import { COLORS } from '$gbusiness/enums';
import { Col, Row, SPACE, Space } from '$gstyles';
import FilterConfigModel from '$gbusiness/models/filterConfig';

import ColumnSelector from './columnSelector';
import FilterSelector from './filterSelector';

interface FilterProps {
  FILTERS: Array<InputRowModel>;
  filterConfig: FilterConfigModel | undefined;
  savedFilters?: Array<any>;
  TABLE: Array<CellModel>;
  colsToDisplay: Array<string>;
  show: boolean;
  onClose: () => void;
  onReset?: () => void;
  onSubmit: Function;
}

const FilterModal: React.FC<FilterProps> = ({
  FILTERS,
  filterConfig,
  savedFilters = [],
  TABLE,
  show,
  onClose,
  onReset,
  onSubmit,
  colsToDisplay: defaultColumns,
}) => {
  const initialValues = input.generateInitialValues(FILTERS, true);
  const [colsToDisplay, setColsToDisplay] = useState(defaultColumns);
  const [shouldSaveFilter, setShouldSaveFilter] = useState(false);
  const [filterName, setFilterName] = useState('');
  const dispatch = useDispatch();

  const colsToFilter = React.useMemo(() => table.generateColsToFilter(TABLE), [TABLE]);

  const saveFilter = filter => {
    const filterObj = {
      filter,
      filterId: filterName,
    };
    if (filterConfig?.saveAction) dispatch(filterConfig.saveAction(filterObj));
  };

  const selectSavedFilter = (filterId, setFieldValue) => {
    const filter = savedFilters.find(f => f.filterId === filterId);
    for (const key in filter.filter) {
      setFieldValue(key, filter.filter[key]);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={values => {
        // Save Filter Here
        if (shouldSaveFilter) saveFilter(values);
        onSubmit(values, colsToDisplay);
      }}>
      {formik => {
        const handleApply = () => {
          formik.handleSubmit();
          onClose();
        };
        const resetFilter = () => {
          formik.resetForm();
          if (onReset) onReset();
        };

        return (
          <Modal show={show} onClose={onClose}>
            <IonHeader>
              <IonToolbar>
                <IonTitle>
                  <Text k={filterConfig?.title || 'TABLE.FILTER.TITLE'} />
                </IonTitle>
                <IonButtons slot="end">
                  <Button onClick={onClose}>Close</Button>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <Form>
                {(colsToFilter.length > 0 || savedFilters?.length) && (
                  <Row>
                    {savedFilters?.length > 0 && (
                      <Col gridSize={6} padding={SPACE.SMALL}>
                        <FilterSelector
                          filters={savedFilters}
                          onChange={v => {
                            formik.resetForm();
                            selectSavedFilter(v, formik.setFieldValue);
                          }}
                        />
                      </Col>
                    )}
                    {colsToFilter.length > 0 && (
                      <Col gridSize={6} padding={SPACE.SMALL}>
                        <ColumnSelector
                          colsToFilter={colsToFilter}
                          values={colsToDisplay}
                          onChange={v => {
                            setColsToDisplay(v);
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                )}
                {FILTERS.map((row, i) => {
                  if (row.isHidden && row.isHidden(formik.values)) return null;
                  return (
                    <Row key={i}>
                      {row.items.map((col, j) => (
                        <Col key={j} padding={SPACE.SMALL} gridSize={col.gridSize || 6}>
                          {utils.input.generateInput(col, formik)}
                        </Col>
                      ))}
                    </Row>
                  );
                })}
              </Form>
              {savedFilters.length > 0 && (
                <Space.Free padding={`${SPACE.MEDIUM} ${SPACE.LARGE}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          setShouldSaveFilter(!shouldSaveFilter);
                        }}
                        checked={shouldSaveFilter}
                        color={COLORS.PRIMARY}
                      />
                    }
                    label={intl('TABLE.FILTER.SAVE_CHECKBOX_LABEL')}
                  />
                  {shouldSaveFilter && (
                    <TextField
                      variant="outlined"
                      label={intl('TABLE.FILTER.SAVE_INPUT_LABEL')}
                      size="small"
                      value={filterName}
                      onChange={e => {
                        setFilterName(e.target.value);
                      }}
                    />
                  )}
                </Space.Free>
              )}
              <div style={{ lineHeight: '30px' }}></div>
            </IonContent>
            <IonFooter>
              <IonGrid>
                <IonRow>
                  {onReset && (
                    <IonCol>
                      <Button fullWidth color={COLORS.SECONDARY} onClick={resetFilter}>
                        <Text k="BUTTON.RESET" />
                      </Button>
                    </IonCol>
                  )}
                  <IonCol>
                    <Button fullWidth color="default" onClick={onClose}>
                      <Text k="BUTTON.CANCEL" />
                    </Button>
                  </IonCol>
                  <IonCol>
                    <Button fullWidth variant="contained" color={COLORS.PRIMARY} onClick={handleApply}>
                      <Text k="BUTTON.APPLY" />
                    </Button>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default FilterModal;
