import React from 'react';
import { Div, SPACE, FONT, WEIGHT } from '$gstyles';

interface HeaderTitleProps {
  size?: string;
  padding?: string;
  margin?: string;
  weight?: number;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  children,
  size = FONT.LARGE,
  padding = SPACE.DEF,
  margin = `${SPACE.LARGE} 0`,
  weight = WEIGHT.SEMI_BOLD,
}) => {
  return (
    <Div fontSize={size} margin={margin} padding={padding} fontWeight={weight}>
      {children}
    </Div>
  );
};

export default HeaderTitle;
