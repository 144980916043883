import React from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$gbusiness/enums';
import { Button, Link } from '$gcomponents/primitives';
import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { input } from '$ghelpers';
import { Text } from '$gintl';
import { FormSection, LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';

import REGISTER_FORM from './registerForm';

interface RegisterScreenProps {
  register: Function;
}

const RegisterScreen: React.FC<RegisterScreenProps> = ({ register }) => {
  const onSubmit = values => register(values);

  const validateForm = values => input.validateError(REGISTER_FORM, values);

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.REGISTER.TITLE" />
      <IonContent>
        <Box.LARGE>
          <LogoHeader />
          <Formik
            enableReinitialize={true}
            initialValues={{}}
            validate={validateForm}
            onSubmit={values => {
              onSubmit(values);
            }}>
            {formik => (
              <Box.MEDIUM padding={SPACE.SMALL}>
                <Form>
                  <FormSection title="SCREEN.REGISTER.SUBTITLE" FORM={REGISTER_FORM} formik={formik} />
                  <Button
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    disabled={!(formik.isValid && formik.dirty)}>
                    <Text k="SCREEN.REGISTER.SUBMIT_BUTTON" />
                  </Button>
                </Form>
                <Space.Ver margin={SPACE.LARGE}>
                  <Link route={ROUTES.LOGIN} routerDirection="back" button>
                    <Text k="SCREEN.REGISTER.LOGIN_LINK" />
                  </Link>
                </Space.Ver>
              </Box.MEDIUM>
            )}
          </Formik>
        </Box.LARGE>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => ({
  loading: state.loading,
});

const mapDispatchToProps = {
  register: authActions.register,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RegisterScreen));
