import * as i18n from 'react-intl-universal';
import { Text } from '$gcomponents/primitives';

const intl = (key, param = undefined) => {
  if (param) return i18n.get(key || 'EMPTY', param);

  return i18n.get(key || 'EMPTY');
};

export { Text };

export default intl;
