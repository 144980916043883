export default interface PaymentRequestModel {
  invoiceType: string;
  email: string;
  customerCode: string;
  address: string;
  zip: string;
  amount: number;
  message: string;
}

export const defaultPaymentRequest = {
  invoiceType: '',
  email: '',
  customerCode: '',
  address: '',
  zip: '',
  amount: 0,
  message: '',
};
