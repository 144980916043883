import _ from 'lodash';
import React from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FastField } from 'formik';

import { MASKS, MODES, TYPES, InputModeType } from '$gbusiness/enums/input';
import intl from '$gintl';
import { configs } from '$configs';
import { mask } from '$ghelpers';
import { IonIcon } from '@ionic/react';
import { Img } from './styles';
import { FormControl } from '@material-ui/core';

import { BSLabel } from './styles';

interface TextInputProps {
  icon?: string;
  label?: string;
  prefix?: string;
  postfix?: string;
  name: string;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  maskType?: string | undefined;
  type?: string;
  maxLength?: number | undefined;
  inputMode?: InputModeType;
  formik?: any;
  size?: string;
  shrink?: boolean;
  enterToSubmit?: boolean;
  handleChange?: (e) => void;
  handleBlur?: Function;
  handleKeyPress?: Function;
}

const defaultProps: TextInputProps = {
  icon: '',
  label: '',
  prefix: '',
  postfix: '',
  name: '',
  readonly: false,
  disabled: false,
  placeholder: '',
  shrink: false,
  inputMode: 'text',
  position: undefined,
  formik: {},
  size: configs.display.inputSize,
  enterToSubmit: false,
  type: TYPES.TEXT,
  handleChange: () => {},
  handleBlur: () => {},
  handleKeyPress: () => {},
};

const getType = inputType => {
  return inputType || 'text';
};

const TextInput: React.FC<TextInputProps> = ({
  icon,
  label,
  prefix,
  postfix,
  name,
  readonly,
  disabled,
  placeholder,
  shrink,
  position,
  inputMode,
  type,
  size,
  maxLength,
  maskType,
  formik,
  enterToSubmit,
  handleChange,
  handleBlur,
  handleKeyPress,
  ...props
}) => {
  const maskInput = value => {
    switch (maskType) {
      case MASKS.PHONE:
        formik.setFieldValue(name, mask.phone(value));
        break;
      case MASKS.SSN:
        formik.setFieldValue(name, mask.ssn(value));
        break;
      case MASKS.CARD_NUMBER:
        formik.setFieldValue(name, mask.cardNumber(value));
        break;
      case MASKS.EXPIRY:
        formik.setFieldValue(name, mask.expiry(value));
        break;
      default:
        break;
    }
  };

  const onKeyPress = e => {
    const charCode = e.which ? e.which : e.keyCode;
    if (enterToSubmit && charCode === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
    if (type === TYPES.NUMBER || inputMode === MODES.NUMERIC) {
      if (type !== TYPES.NUMBER && charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
      }
    }
    if (handleKeyPress) handleKeyPress(e);
    return true;
  };

  const onKeyUp = e => {
    maskInput(e.target.value);
  };

  const onChange = e => {
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };

  const onBlur = e => {
    // any blue handling goes here
    if (formik.handleBlur) formik.handleBlur(e);
    if (handleBlur) handleBlur(e);
  };

  let derivedMaxLength = maxLength;
  if (maskType === MASKS.PHONE) derivedMaxLength = 12;
  else if (maskType === MASKS.SSN) derivedMaxLength = 11;

  let pattern;
  if (inputMode === MODES.NUMERIC) pattern = '[0-9]*';

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const inputSize = size === 'small' ? 'small' : 'medium';
  const labelProps =
    position !== undefined
      ? {
          label: intl(label),
          InputLabelProps: {
            ...(shrink && { shrink }),
          },
        }
      : {};
  const variant = position || 'outlined';

  let startAdornment =
    icon !== '' ? (
      <InputAdornment position="start">
        <IonIcon slot="start" icon={icon} />
      </InputAdornment>
    ) : prefix !== '' ? (
      <InputAdornment position="start">{intl(prefix)}</InputAdornment>
    ) : (
      undefined
    );
  let endAdornment =
    postfix !== '' ? <InputAdornment position="end">{intl(postfix)}</InputAdornment> : undefined;

  return (
    <>
      <FastField name={name}>
        {({ form }) => {
          const value = _.get(form.values, name) || '';
          if (maskType === MASKS.CARD_NUMBER) {
            let imageName;
            switch (value[0]) {
              case '4':
                imageName = 'visa';
                break;
              case '5':
                imageName = 'mastercard';
                break;
              case '3':
                imageName = 'amex';
                break;
              case '6':
                imageName = 'discover';
                break;
              default:
                imageName = 'card';
                break;
            }
            endAdornment = (
              <InputAdornment position="end">
                <Img src={`/assets/img/${imageName}_icon.png`} />
              </InputAdornment>
            );
          }

          return (
            <FormControl size={inputSize} variant={variant} fullWidth>
              {position === undefined && <BSLabel className="bs-label">{intl(label)}</BSLabel>}
              <TextField
                {...labelProps}
                inputProps={{
                  pattern: pattern,
                  maxLength: derivedMaxLength,
                }}
                InputProps={{
                  startAdornment,
                  endAdornment,
                }}
                {...props}
                type={getType(type)}
                name={name}
                onKeyDown={onKeyPress}
                onKeyUp={onKeyUp}
                placeholder={intl(placeholder) || undefined}
                size={inputSize}
                onBlur={onBlur}
                onInput={onChange}
                value={value}
                error={hasError}
                variant={variant}
                helperText={errorMsg}
              />
            </FormControl>
          );
        }}
      </FastField>
    </>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
