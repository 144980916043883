import React from 'react';
import { IonList, IonItem } from '@ionic/react';

import { ROUTES } from '$gbusiness/enums';
import { Text } from '$gintl';

interface PopMenuProps {
  onDismiss?: () => void;
  onClick?: (action) => void;
}

const PopMenu: React.FC<PopMenuProps> = ({ onClick }) => {
  return (
    <IonList>
      <IonItem onClick={onClick} routerLink={`${ROUTES.USERS}/new`}>
        <Text k="SCREEN.USERS.ADD_NEW" />
      </IonItem>
    </IonList>
  );
};

export default PopMenu;
