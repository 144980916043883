import React from 'react';
import intl from '$intl';
import InvoiceModel from '$business/models/invoice';
import { Col, Div, Row, Space, SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Divider } from '@material-ui/core';
import { configs } from '$configs';
import { format } from '$ghelpers/date';

interface InvoiceSectionProps {
  invoice: InvoiceModel;
}

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ invoice }) => {
  const { invoiceNumber, customer, datetime, dueDate, tax, amount } = invoice;
  const total = (tax || 0) + amount;
  const totalCurrency = intl('PRICE', { amount: total });

  // Methods
  const t = key => intl(`SCREEN.PAYMENT.${key}`);

  const ItemSection = ({ left, right }) => {
    return (
      <Flex paddingTop={SPACE.MEDIUM}>
        <Div flex={1} className="left">
          {left}
        </Div>
        <Div flex={1} className="right">
          {right}
        </Div>
      </Flex>
    );
  };

  return (
    <Space.All padding={SPACE.SMALL}>
      <Div className="invoice-section">
        <Flex className="header-section" justifyContent="space-between">
          <Row flex={1}>
            <Col gridSize={6} className="logo-image">
              <img src={`${configs.display.imagePath}logo2.png`} alt={intl('COMMON.APP_TITLE')} />
            </Col>
            <Col gridSize={6} className="invoice-number">
              {invoiceNumber && (
                <>
                  Invoice
                  <br />#{invoice.invoiceNumber}
                </>
              )}
            </Col>
          </Row>
        </Flex>
        <Flex className="client-section" justifyContent="space-between">
          <Row flex={1}>
            <Col gridSize={7} className="left">
              <div className="label">{t('CLIENT_INFO')}</div>
              {customer && customer.owner && <div>{customer.owner}</div>}
              {customer && customer.name && <div>{customer.name}</div>}
              {customer && customer.email && <div>{customer.email}</div>}
            </Col>
            <Col gridSize={5} className="right">
              <div className="label">{t('BILL_TO')}</div>
              {customer && customer.name && <div>{customer.name}</div>}
              {customer && customer.address && <div>{customer.address}</div>}
              {customer && customer.zip && <div>{customer.zip}</div>}
            </Col>
          </Row>
        </Flex>
        <Divider />
        <Flex className="amount-section">
          <Div flex={1}>
            <ItemSection left="Issue Date" right={format(datetime, 'MM.dd.yyyy')} />
            <ItemSection left="Due Date" right={format(dueDate, 'MM.dd.yyyy')} />
            <ItemSection left="Amount Due" right={totalCurrency} />
          </Div>
        </Flex>
        <Divider />
        <Flex className="footer-section">
          <Div flex={1}></Div>
          <Div flex={1}>
            <div className="label">Questions?</div>
            <div>Hiossen.Inc</div>
            <div>AR@hioseen.com</div>
            <div>123-456-7890</div>
          </Div>
        </Flex>
      </Div>
    </Space.All>
  );
};

export default InvoiceSection;
